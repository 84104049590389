import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import Box from 'common/components/box/Box';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Reducers } from 'store/types';
import Loading from '../../common/services/Loading';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from 'Config';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { Paged } from 'api/common/types/Page';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import { UserDto } from 'api/users/models/UserDto';
import { UserSearchCriteria } from 'api/users/models/UserSearchCriteria';

type Props = {
};

const DuesListScreen: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [criteria, setCriteria] = useState<UserSearchCriteria>({
        page: 1,
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        orderColumn: 'u.real_name',
        orderBy: 'asc'
    });

    const [usersPage, setUsersPage] = useState<Paged<UserDto>>();

    const history = useHistory();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_MAGAZINES_WRITE']);

    const getData = async () => {
        try {
            Loading.show();            
            const users = await UsersService.getList(criteria);            
            setUsersPage(users);
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });
            Loading.hide();
        }
    }
 
    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }    

    const tableColumns: ListingTableColumn<UserDto>[] = [
        {
            field: 'realName',
            name: t('dues.list.member_name'),
            onSearch: onTableFilter,
            searchField: 'u.real_name'
        }        
    ];
    

    const navigateToNew = () => {
        history.push('/dues/new');
    }

    const navigateToItem = (row: UserDto) => {
        history.push(`/dues/details/${row.id}`);        
    }

    useEffect(() => {
        getData();
    }, [criteria]);


    return (
        <ScreenTitle title={t('dues.list.title')}>
            <ScreenContainer>
            <ScreenHeader title={t('dues.list.title')} >
                    {hasWritePolicy && <ScreenHeaderButton icon={AddIcon} onClick={() => navigateToNew()} />}
                </ScreenHeader>
                <Box>
                    <ListingTable
                        columns={tableColumns}
                        rows={usersPage?.items || []}
                        onRowClick={row => navigateToItem(row)}
                        allowHover={true}
                        initialSearch={{ colField: 'u.real_name', isOrderAsc: true }}
                    />

                    <PaginationWithInfo
                        itemName={t('dues.list.title')}
                        currentPage={usersPage?.currentPage || 1}
                        pageItems={usersPage?.items.length || 0}
                        totalItems={usersPage?.totalItems || 0}
                        onChange={page => setCriteria({ ...criteria, page })}
                    />
                </Box>
            </ScreenContainer>
        </ScreenTitle>
    );
};

export default DuesListScreen;
