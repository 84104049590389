/* eslint-disable react/prop-types */
import React from 'react';
import styles from './ScreenHeader.module.scss';

type Props = {
    title: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ScreenHeader = ({ children, title, ...props }: Props) => (
    <div
        {...props}
        className={`${styles.container} ${props.className || ''}`}
    >
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.buttons}>
            {children}
        </div>
    </div>
);

export default ScreenHeader;
