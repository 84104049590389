import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Reducers } from '../../store/types';

type Props = {

} & RouteProps;

const ProtectedRoute: React.FC<Props> = ({ children, ...props }: Props) => {
    const isAuthenticated = useSelector<Reducers, boolean>(state => state.authentication.isAuthenticated);
    return (
        <Route {...props} render={({ location }) => {
            return isAuthenticated ? children : <Redirect to={{
                pathname: '/login',
                state: { from: location }
            }} />
        }} />
    );
}

export default ProtectedRoute;
