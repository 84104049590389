import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import { POLICIES } from 'Config';
import { Reducers } from 'store/types';
import Sidebar, { SidebarItem } from '../sidebar/Sidebar';
import styles from './AuthenticatedLayout.module.scss';
import UsersService from 'api/users/UsersService';
import { AlertsTotalsDto } from 'api/alerts/models/AlertsTotalsDto';
import { updateTotals } from 'store/alerts/action';

type Props = {
    children: React.ReactNode;

}

type SidebarItemWithValidation = {
    policies: (keyof typeof POLICIES)[];
    type?: 'OR' | 'AND';
} & SidebarItem;

const AuthenticatedLayout: React.FunctionComponent<Props> = ({ children }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasAlertsPolicy = UsersService.hasPolicies(loggedUser?.policies || [], ['SETTINGUP_ALERTS_READ']);
    const alertsTotals = useSelector<Reducers, AlertsTotalsDto>(state => state.alerts.totals)
    const [isActiveMenu, setActiveMenu] = useState(true);

    const validateItem = useCallback((item: SidebarItemWithValidation) => {
        const type = item.type || 'AND';
        const policies = item.policies || [];
        const userPolicies = loggedUser?.policies || [];

        return UsersService.hasPolicies(userPolicies, policies, type);
    }, [loggedUser]);

    useEffect(() => {
        if (hasAlertsPolicy)
            dispatch(updateTotals());
    }, [])

    const toggleMenu = () => {
        setActiveMenu(!isActiveMenu);
    };

    const sidebarItems: SidebarItemWithValidation[] = useMemo(() => {
        const items: SidebarItemWithValidation[] = [
            {
                text: t('menu.magazines'),
                url: '/magazines',
                policies: [
                    'SETTINGUP_MAGAZINES_READ', 'SETTINGUP_MAGAZINES_WRITE'
                ],
                type: 'OR',
            },
            {
                text: t('menu.categories'),
                url: '/categories',
                policies: [
                    'SETTINGUP_CATEGORIES_READ', 'SETTINGUP_CATEGORIES_WRITE'
                ],
                type: 'OR',
            },
            // {
            //     text: t('menu.dues'),
            //     url: '/dues',
            //     policies: [
            //         'SETTINGUP_USERS_READ', 'SETTINGUP_USERS_WRITE'
            //     ],
            //     type: 'OR',
            // },
            {
                text: t('menu.security'),
                url: '/security',
                policies: [
                    'SETTINGUP_USERS_READ', 'SETTINGUP_USERS_WRITE',
                    'SETTINGUP_ROLES_READ', 'SETTINGUP_ROLES_WRITE',
                    'SETTINGUP_RULES_READ', 'SETTINGUP_RULES_WRITE'
                ],
                type: 'OR',
            },
        ];

        return items.filter(item => validateItem(item));
    }, [loggedUser, validateItem, alertsTotals]);

    return (
        <div className={styles.container} >
            <input type='checkbox' checked={isActiveMenu} readOnly={true} />
            <label className={styles.btnmenu} onClick={toggleMenu}>
                <span className={[styles.bar, styles.top].join(' ')}></span>
                <span className={[styles.bar, styles.middle].join(' ')}></span>
                <span className={[styles.bar, styles.bottom].join(' ')}></span>
            </label>
            <div className={!isActiveMenu ? styles.sidebarContainer : [styles.sidebarContainer, styles.active].join(' ')}>
                <Sidebar items={sidebarItems} />
            </div>
            <div className={!isActiveMenu ? styles.contentContainer : [styles.contentContainer, styles.active].join(' ')} id="scrollHere">
                {children}
            </div>
        </div>
    );
}

export default AuthenticatedLayout;
