import React, { memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './InputSearch.module.scss';
import SearchIcon from 'assets/svg/mobile_search_black.svg';
import CloseIcon from 'assets/svg/mobile_close.svg';

export type Props = {
    onChangeValue: (value: string) => void;
    placeholder: string;
    inputContainerClassName?: any,
} & React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>;

const InputSearch = ({ onChangeValue, placeholder, inputContainerClassName, ...props }: Props) => {
    const { t } = useTranslation();
    const [textLength, setTextLength] = useState<number>(0);
    const inputRef = useRef<HTMLInputElement>(null);

    const clearInput = () => {
        setTextLength(0);
        onChangeValue('');
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    }

    return (
        <div>
            <div className={`${styles.inputContainer} ${inputContainerClassName || ''}`}>
                <input
                    ref={inputRef}
                    type={props.type || 'text'}
                    placeholder={placeholder}
                    {...props}
                    onChange={e => {
                        setTextLength(e.currentTarget.value.length);
                        onChangeValue(e.currentTarget.value);
                    }}
                    className={`${styles.inputContent} ${props.className || ''}`}
                />
                {textLength === 0 && <img className={styles.inputImage} src={SearchIcon} style={{ width: '1rem', height: '1rem' }} />}
                {textLength !== 0 && <img className={`${styles.inputImage} ${styles.pointer}`} src={CloseIcon} onClick={clearInput} style={{ width: '1rem', height: '1rem' }} />}
            </div>

            {textLength === props.maxLength && (
                <div>
                    {t('common.field_max_length', { value: textLength })}
                </div>
            )}
        </div>
    );
}

export default memo(InputSearch);
