/* eslint-disable no-shadow */
/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Redirect, useHistory } from 'react-router-dom';
import styles from './Sidebar.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import Logo from 'assets/img/bva-p_logo.png';
import { ReactComponent as ChevronDownIcon } from 'assets/svg/chevron_down.svg';
import { UserProfile } from 'api/account/models/UserProfile';
import { Reducers } from 'store/types';
import Popover from 'common/components/popover/Popover';
import PopoverItem from 'common/components/popover/PopoverItem';
import { logout, setProfile } from 'store/authentication/action';
import AccountService from 'api/account/AccountService';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import Avatar from 'common/components/avatar/Avatar';

export interface SidebarItem {
    text: string;
    url: string;
    badgeValue?: number;
}

type Props = {
    items: SidebarItem[];
}

const Sidebar: React.FunctionComponent<Props> = ({ items }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const userRefreshToken = useSelector<Reducers, number>(state => state.authentication.refreshToken);
    const isActive = (path: any, match: any, location: any) => !!(match || path === location.pathname);

    const onLogoutClick = () => {
        dispatch(logout());
    }

    const onAccountClick = () => {
        history.push(`/account/details`);
    }

    const getProfile = async () => {
        try {
            if (!user) {
                return;
            }
            const profile = await AccountService.profile();
            dispatch(setProfile(profile));
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get user profile`, error);
        }
    }

    useEffect(() => {
        getProfile();
    }, [userRefreshToken])

    if (!user) {
        return <Redirect to="/login" />
    }

    const buildRole = (user: UserProfile | null) => {
        if (user && user?.roles.length > 0) {
            const role = user?.roles[0];
            if (role === 'ADMINISTRATOR' || role === 'DRIVER' || role === 'PLATFORM_ADMINISTRATOR') {
                return t('common.roles.' + user?.roles[0] as any)
            }
            else {
                return role;
            }
        }
    }

    return (

        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <div className={styles.logoContainer}>
                    <NavLink to={'/'}>
                        <img src={Logo} alt={''} />
                    </NavLink>
                </div>
                <div className={styles.itemsContainer}>
                    {items.map((item, i) => (
                        <NavLink
                            key={i}
                            className={styles.item}
                            strict={false}
                            to={item.url}
                            activeClassName={styles.itemActive}
                            isActive={isActive.bind(this, item.url)}
                            exact={item.url === '/' ? true : false}
                            title={item.text}
                        >
                            <span className={styles.itemText}>
                                {item.text}
                                {item.badgeValue && <span className={styles.floatingBadge}>
                                    {item.badgeValue > 99 ? <small>{item.badgeValue}+</small> : item.badgeValue}
                                </span>}
                            </span>
                        </NavLink>
                    ))}
                </div>
                <div className={styles.bottom}>
                    <Popover
                        align={'center'}
                        positions={['top']}
                        contentContainerClassName={styles.popoverContainer}
                        content={setIsPopoverOpen => <>
                            <PopoverItem onClick={() => { setIsPopoverOpen(false); onAccountClick(); }}>{t('menu.my_profile')}</PopoverItem>
                            <PopoverItem onClick={onLogoutClick}>{t('menu.logout')}</PopoverItem>
                        </>}
                    >
                        {(_, setIsPopoverOpen) => (
                            <div className={styles.profileItem} onClick={() => setIsPopoverOpen(true)}>
                                <Avatar src={user.mediaUrl} className={styles.profileAvatar} size="x4" />
                                <div className={styles.profileInfo}>
                                    <div className={styles.profileName}>
                                        <span>{user?.realName}</span>
                                        <ChevronDownIcon className={styles.profileChevron} />
                                    </div>
                                    <div className={styles.profileRole}>{buildRole(user)}</div>
                                </div>
                            </div>
                        )}
                    </Popover>
                    <div className={styles.copyright}>
                        <p>{t('menu.copyright', { year: new Date().getFullYear() })}</p>
                        <p>{t('menu.all_rights_reserved')}</p>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Sidebar;