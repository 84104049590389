/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Input.module.scss';

type InputPreset = 'default' | 'login';

export type Props = {
    preset?: InputPreset;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, Props>(({ preset, onChange, ...props }, ref) => {
    const presetClassName = preset ? styles[preset] : styles.default;

    const { t } = useTranslation();
    const [textLength, setTextLength] = useState<number>(0);

    return (
        <>
            <input
                ref={ref}
                type={props.type || 'text'}
                {...props}
                onChange={e => {
                    setTextLength(e.currentTarget.value.length);
                    if (onChange) {
                        onChange(e);
                    }
                }}
                onBlur={event => {
                    event.target.value = event.target.value.trim();
                }}
                className={`${styles.input} ${presetClassName} ${props.className || ''} ${props.disabled ? styles.disabled : ''} `}
            />
            {textLength === props.maxLength && <div className={styles.maxLength}>{t('common.field_max_length', { value: textLength })}</div>}
        </>
    );
});

export default memo(Input);
