import { UserProfile } from 'api/account/models/UserProfile';
import Box from 'common/components/box/Box';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Reducers } from 'store/types';
import { LOGGER_LOG_TYPE } from 'Config';
import { Col, Row } from 'react-flexbox-grid';
import Label from 'common/components/label/Label';
import styles from './CategoriesScreen.module.scss';
import { useForm } from 'react-hook-form';
import InputError from 'common/components/inputError/InputError';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import Input from 'common/components/input/Input';
import Button from 'common/components/button/Button';
import CategoriesService from 'api/categories/CategoriesService';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import UsersService from 'api/users/UsersService';
import { CategoriesDto } from 'api/categories/models/CategoriesDto';
import FormItem from 'common/components/formItem/FormItem';
import { UsersSelectItemDto } from 'api/users/models/UserDto';
import CheckBox from 'common/components/checkBox/CheckBox';



export type CategorieRouteParams = {
    type: 'details' | 'edit' | 'new';
    categorieId: string;
    itemType: 'list' | 'details' | 'edit' | 'new';
    itemId: string;
}

type Props = {
};

const CategoriesScreen: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { categorieId, type } = useParams<CategorieRouteParams>();
    const isDetails = type === 'details';
    const isNew = type === 'new';
    const history = useHistory();
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_CATEGORIES_WRITE']);
    const form = useForm<CategoriesDto>();
    const { register, handleSubmit, formState: { errors }, reset } = form;
    const [categorie, setCategorie] = useState<CategoriesDto | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [usersList, setUsersList] = useState<UsersSelectItemDto[]>([]);

    const getData = async () => {
        try {

            const users = await UsersService.getAll();
            setUsersList(users);

            if (categorieId) {
                Loading.show();
                const result = await CategoriesService.getById(categorieId);
                
                //check users
                users.filter(c => result.usersId?.find(e => e.userId === c.id) != null).forEach((e: UsersSelectItemDto) => {
                    e.checked = true;
                });

                setCategorie(result);            
                reset(result);
                Loading.hide();
            }
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }

    }

    const onSubmit = async (formData: CategoriesDto) => {
        try {
            Loading.show();
            
            formData.categorieUsers = usersList.flatMap(item => item.checked ? [item.id] : []);

            if (isNew) {
                const id = await CategoriesService.create(formData);
                history.push(`/categories/details/${id}`);
            } else {
                await CategoriesService.update(formData);
                history.push(`/categories/details/${categorieId}`);
            }

            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't create or update categories`, error);
            Loading.hide();
        }
    };

    useEffect(() => {
        getData();
    }, [type]);

    const onCancel = () => {
        history.push(isDetails || isNew ? '/categories' : `/categories/details/${categorieId}`);
    }

    const navigateToEdit = () => {
        history.push(`/categories/edit/${categorieId}`)
    }

    const onCancelRemove = () => {
        setShowRemoveModal(false);
    }

    const onRemove = async () => {
        try {
            Loading.show();
            setShowRemoveModal(false);
            await CategoriesService.remove(categorieId);
            history.push('/categories');
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't remove the categorie`, error);
            Loading.hide();
        }
    }






    const canCreate = (): JSX.Element => {
        return <Box>
            <form onSubmit={handleSubmit(onSubmit)} >
                <div className={styles.container}>
                    <Row className={styles.rowSpace}>
                        <Col className={styles.colSpace} xs={12} md={6}>
                            <FormItem>
                                <Label className={styles.label}>{t('categories.details.name')} *</Label>
                                <Input
                                    placeholder={t('categories.details.name')}
                                    defaultValue={categorie?.name}
                                    {...register('name', { required: true })}
                                    disabled={isDetails}
                                />
                                <InputError error={errors?.name} />
                            </FormItem>
                        </Col>
                    </Row>                
                    <div className={styles.container}>
                        <div className={styles.configContainer}>
                            <div className={styles.usersContainer}>
                                <div className={styles.searchContainer}>
                                    {/* <InputSearch inputContainerClassName={styles.search} onChangeValue={debouncedUser} placeholder={t('common.search')}></InputSearch> */}
                                </div>
                                <table className={styles.table}>
                                    <thead>
                                        <tr>
                                            <td className={styles.tableHeader}>
                                                <span className={styles.tableHeaderTitle}>{t('users.title')}</span>
                                                {/* <CheckBox checked={isAllUsersChecked(filterUser != '')} onChange={e => { onAllUsersChecked(e.target.checked) }} /> */}
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(usersList).map((row, rowIndex) => (
                                            <tr key={`row-u-${rowIndex}`} className={styles.rowHover}>
                                                <td className={styles.bodyColumn}>
                                                    <div className={styles.userContainer}>
                                                        <div className={styles.userInfo}>
                                                            <span className={styles.name}>{row.realName}</span>
                                                            <span className={styles.email}>({row.userName})</span>
                                                        </div>
                                                        <div className={styles.checkbox}>
                                                            <CheckBox
                                                                onChange={e => {
                                                                    setUsersList([
                                                                        ...usersList.map(r => {
                                                                            if (r.id === row.id) {
                                                                                r.checked = e.target.checked;
                                                                            }
                                                                            return r;
                                                                        }),
                                                                    ]);                                                                    
                                                                }}
                                                                checked={row.checked || false}
                                                                key={row.id}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                </div>
                <QuestionYesNo
                    onNo={onCancelRemove}
                    onYes={onRemove}
                    isVisible={showRemoveModal}
                    message={t('common.messages.remove_record_with_ident', { name: categorie?.name ?? '' })} />
                <div className={styles.buttonContainer}>
                    {isDetails &&
                        <>
                            <Button
                                preset={'secondary'}
                                type='button'
                                text={t('common.cancel')}
                                onClick={() => onCancel()}
                            />
                            <Button
                                preset={'danger'}
                                type='button'
                                text={t('common.remove')}
                                onClick={() => setShowRemoveModal(true)}
                            />
                            <Button
                                type='button'
                                text={t('common.edit')}
                                onClick={() => navigateToEdit()}
                            />
                        </>
                    }
                    {!isDetails &&
                        <>
                            <Button preset={'secondary'}
                                type='button'
                                text={t('common.cancel')}
                                onClick={() => onCancel()}
                            />
                            <Button
                                type='submit'
                                text={t('common.save')}
                            />
                        </>
                    }
                </div>
            </form>
        </Box >;
    }

    const cannotPermission = (): JSX.Element => {
        return <Box>
            <div className={styles.permissionContainer}>
                <div>{t('common.no_permission')}</div>
                <Button
                    preset={'secondary'}
                    type='button'
                    text={t('common.cancel')}
                    onClick={() => onCancel()}
                />
            </div>
        </Box >;
    }

    return (
        <ScreenTitle title={t('categories.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('categories.title')} />
                {hasWritePolicy ? canCreate() : cannotPermission()}
            </ScreenContainer>
        </ScreenTitle>
    );
};

export default CategoriesScreen;
