/* eslint-disable react/display-name */
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { registerLocale, ReactDatePickerProps as DateProps } from 'react-datepicker';
import styles from './DateTimePicker.module.scss'
import { useTranslation } from 'react-i18next';
import { pt, fr, enGB  } from 'date-fns/locale';
registerLocale('pt', pt)
registerLocale('fr', fr) 
registerLocale('enGB', enGB)

export type Props = {
} & DateProps;

const DateTimePicker = React.forwardRef<DatePicker, Props>(({ ...props  }, ref) => {
  const { t, i18n } = useTranslation();  
  return (
      <DatePicker
        {...props}
        ref={ref}
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
        wrapperClassName={styles.fullWidth}
        locale={i18n.language}
        
      />
    );
});

export default DateTimePicker;
