import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import LoadingProvider from './common/components/loading/LoadingProvider';
import i18n from './common/services/I18n';
import DefaultRouter from './Router';
import Setup from './Setup';
import store from './store/store';

const App: React.FunctionComponent = () => {
    return (
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <ToastProvider
                    autoDismiss
                    autoDismissTimeout={5000}
                    placement="top-right"
                >
                    <Setup>
                        <DefaultRouter />
                    </Setup>
                    <LoadingProvider />
                </ToastProvider>
            </I18nextProvider>
        </Provider>
    );
}

export default App;
