import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { AssociationDto } from 'api/associations/models/AssociationDto';
import { AssociatesTypeDto } from 'api/associatesType/models/AssociatesTypeDto';
import Loading from 'common/services/Loading';
import AssociationsService from 'api/associations/AssociationsService';
import Logger from 'common/services/Logger';
import Button from 'common/components/button/Button';
import styles from './AssociationsDetails.module.scss';
import InputError from 'common/components/inputError/InputError';
import Input from 'common/components/input/Input';
import Label from 'common/components/label/Label';
import FormItem from 'common/components/formItem/FormItem';
import { useFieldArray, useForm } from 'react-hook-form';
import { DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE } from 'Config';
import GreyArrow from 'assets/svg/Arrow-grey.svg';
import React, { useEffect, useState } from 'react';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { useHistory, useParams } from 'react-router-dom';
import CustomFile from 'common/models/CustomFile';
import { Col, Row } from 'react-flexbox-grid';
import MediaSelector from 'common/components/mediaSelector/MediaSelector';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { FaTrash } from 'react-icons/fa';

const AssociationsDetails = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const { id: associationId, type } = useParams<{ id: string, type: string }>();
    const [association, setAssociation] = useState<AssociationDto>();
    const isDetails = type === 'details';
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<AssociationDto | null>(null);
    const history = useHistory();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasAssociationsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ASSOCIATIONS_WRITE']);
    const [mediaFile, setMediaFile] = useState<CustomFile | null>(null);
    const [removeMedia, setRemoveMedia] = useState(false);
    const form = useForm<AssociationDto>({ defaultValues: { ...association } });
    const { register, handleSubmit, formState: { errors }, reset, control } = form;
    const { fields: associatesTypesList, append: appendAssociatesType, remove: removeAssociatesType } = useFieldArray({
        control,
        name: 'associatesTypes',
        keyName: 'formId'
    });


    const getData = async () => {
        try {
            let a: AssociationDto = {
                id: ''
                , name: ''
                , system: false
                , readOnly: false
                , checked: false
                , removeMedia: null
                , media: null
                , associatesTypes: []
            };
            if (!!associationId) {
                Loading.show();
                a = await AssociationsService.getById(associationId);                
            }
            setAssociation(a);
            reset(a);
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't get catalog associations types`,
                error
            );
            Loading.hide();
        }
    }

    useEffect(() => {
        getData();
    }, [isDetails]);


    const goToList = () => {
        history.push(`/security/associations`);
    };

    const onCancel = () => {
        if (isDetails) {
            history.push('/security/associations');
        } else if (!associationId) {
            history.goBack();
        } else {
            getData();
            history.push(`/security/associations/details/${associationId}`);
        }
    };

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }
        try {
            await AssociationsService.remove(itemToRemove);
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
            onCancelRemove();
            goToList();
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete association`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    const showRemoveItemDialog = async (item: AssociationDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onAddAvatar = (files: CustomFile[]) => {
        if (files.length > 0) {
            setMediaFile(files[0]);
        }
    }

    const onRemoveAvatar = () => {
        setRemoveMedia(true);
        if (association) {
            setAssociation({
                ...association,
                media: null,
            });
        }
    }

    const addItemAssociatesType = () => {       
        const associate: AssociatesTypeDto = {
            id: null
            , name: ''
        };        
        appendAssociatesType(associate);
    }

    const removeItemAssociatesType = (rowIndex: any) => {
        if (association) {
            removeAssociatesType(rowIndex);
        }
    }

    const onSubmit = async (formData: AssociationDto) => {
        try {
            if (!hasAssociationsWritePolicy) { return; }
            
            Loading.show();
            if (association) {
                association.name = formData.name;
                association.removeMedia = removeMedia;
                association.associatesTypes = formData.associatesTypes;                
            }                            

            if (association && association.id != '') {
                await AssociationsService.update(association, mediaFile);
            } else if (association) {
                await AssociationsService.create(formData, mediaFile);
            }

            Loading.hide();
            addToast(t('common.messages.record_save_success'), {
                appearance: 'success',
            });
            goToList();
        } catch (error) {
            addToast(t('common.messages.record_save_error'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't create or update association`,
                error
            );
            Loading.hide();
        }
    };

    return (
        <div>
            <div className={styles.pageNameContainer}>
                <div className={styles.pageNameContent} onClick={() => goToList()} >
                    <img className={styles.pageNameContentImage} src={GreyArrow} />
                    <span className={styles.pageNameContentText}>
                        {t('associations.title')}
                    </span>
                </div>
            </div>

            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>

                <Row>
                    <Col xs={12} md={6}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('associations.name')}*
                            </Label>
                            <Input
                                placeholder={t('associations.name')}
                                defaultValue={association?.name}
                                {...register('name', { ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                disabled={isDetails}
                            />
                            <InputError error={errors.name} />
                        </FormItem>
                    </Col>

                    {(type != 'details' || (type == 'details' && association?.media)) && <Col xs={12} md={6}>
                        <div className={styles.avatarChooser}>
                            <MediaSelector
                                isDetails={type == 'details'}
                                isMulti={false}
                                onAddMedias={onAddAvatar}
                                onRemoveMedia={onRemoveAvatar}
                                initialMedias={association?.media ? [association?.media] : []}
                            />
                        </div>
                    </Col>}
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <table>
                            <thead>
                                <tr>
                                    <th>{t('associations.associates_types')}</th>
                                    <th>
                                        {!isDetails && <ScreenHeaderButton icon={AddIcon} onClick={() => addItemAssociatesType()} />}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {associatesTypesList.map((row, rowIndex: number) => (
                                    <tr key={row.formId}>
                                        <td key={`col-${rowIndex}`}>
                                            <Input
                                                placeholder={t('associations.associates_types')}
                                                defaultValue={row.name || ''}
                                                {...register(`associatesTypes.${rowIndex}.name`, { required: true })}
                                                disabled={isDetails}
                                            />
                                        </td>
                                        <td>
                                            {!isDetails && <FaTrash size={16} onClick={() => removeItemAssociatesType(rowIndex)} />}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </Col>
                </Row>


                <div className={styles.buttonContainer}>
                    <Button preset={'secondary'}
                        type='button'
                        text={t('common.cancel')}
                        onClick={() => onCancel()} />

                    {isDetails && hasAssociationsWritePolicy &&
                        <Button
                            type="button"
                            text={t('common.remove')}
                            preset={'danger'}
                            onClick={() => showRemoveItemDialog({ id: association?.id } as AssociationDto)} />
                    }
                    {isDetails && hasAssociationsWritePolicy &&
                        <Button
                            type='button'
                            text={t('common.edit')}
                            onClick={() => history.push(`/security/associations/edit/${associationId}`)} />
                    }
                    {!isDetails && hasAssociationsWritePolicy &&
                        <Button
                            type='submit'
                            text={t('common.save')} />
                    }
                </div>

            </form>
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_record_with_ident', { name: itemToRemove?.name ?? '' })}></QuestionYesNo>
        </div>
    );
};

export default AssociationsDetails;
