/* eslint-disable react/prop-types */
import React from 'react';
import styles from './Label.module.scss';

type Props = {
    required?: boolean;
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;

const Label: React.FC<Props> = ({ required = false, children, ...props }: Props) => {

    return (
        <label
            {...props}
            className={`${styles.label} ${props.className || ''}`}
        >
            {children} {required ? ' *' : ''}
        </label>
    );
}

export default Label;
