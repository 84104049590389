import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import Box from 'common/components/box/Box';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Reducers } from 'store/types';
import Loading from '../../common/services/Loading';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE_LARGE } from 'Config';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { Paged } from 'api/common/types/Page';
import { FolderSearchCriteria } from 'api/folders/models/FolderSearchCriteria';
import { FolderDto } from 'api/folders/models/FolderDto';
import FoldersService from 'api/folders/FoldersService';
import { Row } from 'react-flexbox-grid';
import ReactTooltip from 'react-tooltip';
import Card from 'common/components/card/Card';
import InputSearch from 'common/components/inputSearch/InputSearch';
import { useDebouncedCallback } from 'use-debounce/lib';
import { ServerRouteParams } from 'screens/servers/ServersScreen';

type Props = {
    isServer?: boolean;
};

const FoldersListScreen: React.FC<Props> = ({ isServer }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const { serverId } = useParams<ServerRouteParams>();

    const [criteria, setCriteria] = useState<FolderSearchCriteria>({
        page: 1,
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE_LARGE,
        orderColumn: 'name',
        orderBy: 'asc',
        serverId: isServer ? serverId : undefined
    });

    const [folderPage, setFolderPage] = useState<Paged<FolderDto>>();

    const history = useHistory();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVerificationsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_FOLDERS_WRITE']);

    const getData = async () => {
        try {
            Loading.show();
            const folders = await FoldersService.getList(criteria);
            setFolderPage(folders);
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const navigateToNew = () => {
        history.push('/folders/new');
    }

    const debounced = useDebouncedCallback((value: string) => {
        setCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);

    useEffect(() => {
        getData();
    }, [criteria]);

    return (
        <ScreenTitle title={t('folders.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('folders.title')} >
                    <InputSearch onChangeValue={debounced} placeholder={t('servers.list.placeholders.search')} />
                    {hasVerificationsWritePolicy && <ScreenHeaderButton icon={AddIcon} onClick={() => navigateToNew()} />}
                </ScreenHeader>
                <Box>
                    <div>
                        <Row>
                            {(folderPage?.items || []).map((elem: FolderDto, index: number) => {
                                return <Card key={index} folder={elem} index={index} large />;
                            })}
                        </Row>
                    </div>
                    <PaginationWithInfo
                        itemName={t('folders.title')}
                        currentPage={folderPage?.currentPage || 1}
                        pageItems={folderPage?.items.length || 0}
                        totalItems={folderPage?.totalItems || 0}
                        onChange={page => setCriteria({ ...criteria, page })}
                    />
                </Box>
                <ReactTooltip />
            </ScreenContainer>
        </ScreenTitle>
    );
};

export default FoldersListScreen;