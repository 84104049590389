import React from 'react';
import Moment from 'moment';
import { DATE_FORMAT_DEFAUT, DEFAULT_LANGUAGE, STORAGE } from '../../../Config';
import Storage from '../../services/Storage';

type Props = {
    value: string | Date;
    format?: string;
    className?: any; 
}

const DateFormat: React.FC<Props> = ({ value, format, className }: Props) => {
    Moment.locale(Storage.get(STORAGE.CURRENT_LOCALE) || DEFAULT_LANGUAGE);
    return (
        <span className={className}>
            {value && Moment(value).format(format??DATE_FORMAT_DEFAUT)}
        </span>
    );
}

export default DateFormat;
