import { UserProfile } from 'api/account/models/UserProfile';
import Box from 'common/components/box/Box';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Reducers } from 'store/types';
import { LOGGER_LOG_TYPE } from 'Config';
import { Col, Row } from 'react-flexbox-grid';
import Label from 'common/components/label/Label';
import styles from './ServersScreen.module.scss';
import { useForm } from 'react-hook-form';
import { DiskSpaceInfoDto, ServerDto } from 'api/servers/models/ServerDto';
import InputError from 'common/components/inputError/InputError';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import Input from 'common/components/input/Input';
import Button from 'common/components/button/Button';
import ServersService from 'api/servers/ServersService';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import UsersService from 'api/users/UsersService';
import ServerDiskChart from './components/serverDiskChart/ServerDiskChart';
import Utils from 'common/services/Utils';
import SummernoteController from 'common/components/summernoteController/SummernoteController';
import Tabs, { TabItem } from 'common/components/tabs/Tabs';
import ProjectTab from './tabs/projectTab/ProjectTab';
import AlertTab from './tabs/alertTab/AlertTab';


export type ServerRouteParams = {
    type: 'details' | 'edit' | 'new';
    serverId: string;
    tabId: string;
    itemType: 'list' | 'details' | 'edit' | 'new';
    itemId: string;
}

type Props = {
};

const ServersScreen: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { serverId, type, tabId } = useParams<ServerRouteParams>();
    const isDetails = type === 'details';
    const isNew = type === 'new';
    const history = useHistory();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_SERVERS_WRITE']);

    const form = useForm<ServerDto>();
    const { register, handleSubmit, formState: { errors }, reset } = form;
    const [server, setServer] = useState<ServerDto | null>(null);

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

    const getData = async () => {
        if (serverId) {
            try {
                Loading.show();
                const result = await ServersService.get(serverId);
                result.diskSpace?.forEach((x: DiskSpaceInfoDto) => x.color = !x.color ? Utils.getRandomColor() : x.color);
                setServer(result);
                reset(result);
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.error_load_info'), { appearance: 'error' });
                Loading.hide();
            }
        }
    }

    const onSubmit = async (formData: ServerDto) => {
        try {
            Loading.show();
            if (isNew) {
                const id = await ServersService.create(formData);
                history.push(`/servers/details/${id}`);
            } else {
                await ServersService.update(formData);
                history.push(`/servers/details/${serverId}`);
            }

            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't create or update servers`, error);
            Loading.hide();
        }
    };

    useEffect(() => {
        getData();
    }, [type]);

    const onCancel = () => {
        history.push(isDetails || isNew ? '/servers' : `/servers/details/${serverId}`);
    }

    const navigateToEdit = () => {
        history.push(`/servers/edit/${serverId}`)
    }

    const onCancelRemove = () => {
        setShowRemoveModal(false);
    }

    const onRemove = async () => {
        try {
            Loading.show();
            setShowRemoveModal(false);
            await ServersService.remove(serverId);
            history.push('/servers');
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't remove the server`, error);
            Loading.hide();
        }
    }

    const tabs: TabItem[] = useMemo(() => {
        const items: TabItem[] = [{
            id: 'projects',
            title: t('servers.details.project_list'),
            content: <ProjectTab projects={server?.projectList ?? []} />
        },        
        {
            id: 'alerts',
            title: t('alerts.title'),
            badge: (server?.alertList ?? []).filter(x => x.isRead === false).length,
            content: <AlertTab alerts={server?.alertList ?? []} reload={getData} />
        }];

        return items;
    }, [server?.projectList, server?.alertList]);

    const navigateToTab = (id: string) => {
        history.push(`/servers/details/${serverId}/${id}/list`);
    }

    const canCreate = (): JSX.Element => {
        return <Box>
            <form onSubmit={handleSubmit(onSubmit)} >
                <div className={styles.container}>
                    <Row className={styles.rowSpace}>
                        <Col className={styles.colSpace} xs={12} md={6}>
                            <Label className={styles.label}>{t('servers.details.name')} *</Label>
                            <Input
                                placeholder={t('servers.details.name')}
                                defaultValue={server?.name}
                                {...register('name', { required: true })}
                                disabled={isDetails}
                            />
                            <InputError error={errors?.name} />
                        </Col>
                        <Col className={styles.colSpace} xs={12} md={6}>
                            <Label className={styles.label}>{t('servers.details.hostname')} *</Label>
                            <Input
                                placeholder={t('servers.details.hostname')}
                                defaultValue={server?.hostname}
                                {...register('hostname', { required: true })}
                                disabled={isDetails}
                            />
                            <InputError error={errors?.hostname} />
                        </Col>
                        <Col className={styles.colSpace} xs={12} md={6}>
                            <Label className={styles.label}>{t('servers.details.min_size')} *</Label>
                            <Input
                                placeholder={t('servers.details.min_size')}
                                defaultValue={server?.minSize}
                                {...register('minSize', { required: true })}
                                disabled={isDetails}
                                type={'number'}
                            />
                            <InputError error={errors?.minSize} />
                        </Col>
                        <Col className={styles.colSpace} xs={12}>
                            <Label className={styles.label}>{t('servers.details.description')} *</Label>
                            <SummernoteController name={'description'} placeholder={t('servers.details.placeholders.description')} form={form} required isDisabled={isDetails} />
                            <InputError error={errors?.description} />
                        </Col>
                    </Row>
                    {isDetails && !!(server?.diskSpace?.length) && <Row>
                        <Col xs={12}>
                            <Label className={styles.label}>{t('servers.details.disk_details')}</Label>
                            <ServerDiskChart diskSpace={server?.diskSpace} />
                        </Col>
                    </Row>}

                    {isDetails && <div className={styles.tabContainer}>
                        <Tabs
                            items={tabs}
                            activeTabId={tabId ?? 'projects'}
                            onChange={navigateToTab}
                        />
                    </div>}
                </div>
                <QuestionYesNo
                    onNo={onCancelRemove}
                    onYes={onRemove}
                    isVisible={showRemoveModal}
                    message={t('common.messages.remove_record_with_ident', { name: server?.name ?? '' })} />
                <div className={styles.buttonContainer}>
                    {isDetails &&
                        <>
                            <Button
                                preset={'secondary'}
                                type='button'
                                text={t('common.cancel')}
                                onClick={() => onCancel()}
                            />
                            <Button
                                preset={'danger'}
                                type='button'
                                text={t('common.remove')}
                                onClick={() => setShowRemoveModal(true)}
                            />
                            <Button
                                type='button'
                                text={t('common.edit')}
                                onClick={() => navigateToEdit()}
                            />
                        </>
                    }
                    {!isDetails &&
                        <>
                            <Button preset={'secondary'}
                                type='button'
                                text={t('common.cancel')}
                                onClick={() => onCancel()}
                            />
                            <Button
                                type='submit'
                                text={t('common.save')}
                            />
                        </>
                    }
                </div>
            </form>
        </Box >;
    }

    const cannotCreate = (): JSX.Element => {
        return <Box>
            <div className={styles.permissionContainer}>
                <div>{t('common.no_permission')}</div>
                <Button
                    preset={'secondary'}
                    type='button'
                    text={t('common.cancel')}
                    onClick={() => onCancel()}
                />
            </div>
        </Box>;
    }

    return (
        <ScreenTitle title={t('servers.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('servers.title')} />
                {hasWritePolicy ? canCreate() : cannotCreate()}
            </ScreenContainer>
        </ScreenTitle>
    );
};

export default ServersScreen;
