 import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
 import { CategorieSearchCriteria } from './models/CategoriesSearchCriteria';
 import { CategoriesDto } from './models/CategoriesDto';

class CategoriesService {

   
    public getList(criteria: CategorieSearchCriteria) {
        return Http.get<Paged<CategoriesDto>>('categories', criteria);
    }
        
    public getAll() {
        return Http.get<CategoriesDto[]>('categories/all');
    }

    public getById(id: string) {
        return Http.get<CategoriesDto>('categories/' + id);
    }

    public create(model: CategoriesDto) {
        return Http.post('categories', model);
    }

    public update(model: CategoriesDto) {
        return Http.put('categories', model);
    }
    
    public remove(id: string) {
        return Http.put('categories/deactivate', { id: id });
    }
   

}

export default new CategoriesService();