import React, { useRef } from 'react';
import InputGroup, { Props as InputGroupProps } from './InputGroup';
import { Controller, RegisterOptions } from 'react-hook-form';

type Props = InputGroupProps & {
    name: string;
    form: any;
    hideMessageLength?: boolean;
    placeholder?: string;
    clickInText?: boolean;
    onClickInText?: () => void;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
};

const InputGroupController = ({ name, form, hideMessageLength, placeholder, onValueChange, clickInText, onClickInText, rules, ...props }: Props, ref: any) => {
    ref = useRef(null);
    return (
        <Controller
            control={form.control}
            name={name}
            render={({ field }) => {
                return (
                    <InputGroup
                        hideMessageLength={hideMessageLength}
                        value={field.value}
                        name={name}
                        clickInText={clickInText}
                        onClickInText={onClickInText}
                        ref={ref as any}
                        placeholder={placeholder}
                        {...props}
                        onValueChange={value => {
                            const newValue = props.type === 'number' || props.type === 'integer' ? Number(value || 0) : value;
                            field.onChange(onValueChange ? onValueChange(newValue) : newValue);
                        }}
                    />
                );
            }}
            defaultValue={form.getValues(name) ?? ''}
            rules={rules}
        />
    );
};

export default React.forwardRef<HTMLInputElement, Props>(InputGroupController);
