/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import styles from './LoginScreen.module.scss';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import Button from '../../common/components/button/Button';
import Input from '../../common/components/input/Input';
import Label from '../../common/components/label/Label';
import InputError from '../../common/components/inputError/InputError';
import FormItem from '../../common/components/formItem/FormItem';
import { useForm } from 'react-hook-form';
import AccountService from '../../api/account/AccountService';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateUser } from '../../store/authentication/action';
import Loading from '../../common/services/Loading';
import { Reducers } from '../../store/types';
import MainLogo from 'assets/img/bva-p_logo.png';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

enum ScreenType {
    LOGIN,
    RECOVER_PASSWORD,
    SET_PASSWORD,
}

type LoginForm = {
    email: string;
    password: string;
};

type RecoverPasswordForm = {
    email: string;
};

type SetPasswordForm = {
    password: string;
    passwordRepeat: string;
};



const RecoverPasswordScreen: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const {
        register: registerLogin,
        handleSubmit: handleSubmitLogin,
        formState: { errors: errorsLogin },
    } = useForm<LoginForm>();
    const {
        register: registerRecoverPassword,
        handleSubmit: handleSubmitRecoverPassword,
        formState: { errors: errorsRecoverPassword },
    } = useForm<RecoverPasswordForm>();
    const {
        register: registerSetPassword,
        handleSubmit: handleSubmitSetPassword,
        formState: { errors: errorsSetPassword },
        watch: watchSetPassword,
    } = useForm<SetPasswordForm>();
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector<Reducers, boolean>(
        state => state.authentication.isAuthenticated
    );
    const routeLocation = useLocation<any>();
    const query = useQuery();
    const queryToken = query.get('token');
    const queryEmail = query.get('email');
    const [screen, setScreen] = useState<ScreenType>(
        queryToken && queryEmail ? ScreenType.SET_PASSWORD : ScreenType.LOGIN
    );

    const onSubmitLogin = async ({ email, password }: LoginForm) => {
        try {
            Loading.show();
            const response = await AccountService.login({
                email: email,
                password,
            });
            if (response) {
                dispatch(authenticateUser(response.profile, response.token));
            }
            Loading.hide();
        } catch (error) {
            addToast(t('login.error_login'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const onSubmitRecoverPassword = async ({ email }: RecoverPasswordForm) => {
        try {
            Loading.show();
            await AccountService.generateResetPasswordCode({ email: email });

            addToast(t('login.recover_password_email_sent'), {
                appearance: 'success',
            });
            setScreen(ScreenType.LOGIN);

            Loading.hide();
        } catch (error) {
            addToast(t('login.error_recover_password'), {
                appearance: 'error',
            });
            Loading.hide();
        }
    };

    const onSubmitSetPassword = async ({ password }: SetPasswordForm) => {
        try {
            if (!queryToken || !queryEmail || !password) {
                return;
            }

            Loading.show();
            await AccountService.resetPassword({
                token: queryToken,
                email: queryEmail,
                password,
            });

            addToast(t('login.password_reset_success'), {
                appearance: 'success',
            });
            setScreen(ScreenType.LOGIN);

            Loading.hide();
        } catch (error) {
            addToast(t('login.error_recover_password'), {
                appearance: 'error',
            });
            Loading.hide();
        }
    };

    if (isAuthenticated && screen != ScreenType.SET_PASSWORD) {
        return <Redirect to={routeLocation?.state?.from || '/'} />;
    }

    return (
        <ScreenTitle title={t('login.title')}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <img className={styles.imageLogo} src={MainLogo} alt='main logo' />
                    {screen === ScreenType.LOGIN && (
                        <form
                            onSubmit={handleSubmitLogin(onSubmitLogin)}
                            className={styles.loginForm}
                        >
                            <h3 className={styles.loginTitle}>
                                {t('login.welcome')}
                                
                            </h3>

                            <FormItem className={styles.formItem}>
                                <Label>{t('login.email')}</Label>
                                <Input
                                    placeholder={t('login.email')}
                                    {...registerLogin('email', { required: true })}
                                />
                                <InputError error={errorsLogin.email} />
                            </FormItem>

                            <FormItem className={styles.formItem}>
                                <Label>{t('login.password')}</Label>
                                <Input
                                    type='password'
                                    placeholder={t('login.password')}
                                    {...registerLogin('password', { required: true })}
                                />
                                <InputError error={errorsLogin.password} />
                            </FormItem>

                            <span
                                className={styles.recoverPasswordLink}
                                onClick={() =>
                                    setScreen(ScreenType.RECOVER_PASSWORD)
                                }
                            >
                                {t('login.recover_password_link')}
                            </span>

                            <Button
                                type='submit'
                                preset={'primary'}
                                text={t('login.login_button')}
                                className={`${styles.alignRight} ${styles.button}`}
                            />
                        </form>
                    )}
                    {screen === ScreenType.RECOVER_PASSWORD && (
                        <form
                            onSubmit={handleSubmitRecoverPassword(
                                onSubmitRecoverPassword
                            )}
                            className={styles.recoverPasswordForm}
                        >
                            <p className={styles.recoverPasswordTitle}>
                                {t('login.recover_password_title')}
                            </p>
                            <p className={styles.recoverPasswordSubtitle}>
                                {t('login.recover_password_subtitle')}
                            </p>

                            <FormItem className={styles.marginTop2}>
                                <Label>{t('login.email')}</Label>
                                <Input
                                    placeholder={t('login.email')}
                                    {...registerRecoverPassword('email', { required: true })}
                                    style={{ 'width': '100%' }}
                                />
                                <InputError error={errorsRecoverPassword.email} />
                            </FormItem>

                            <div className={`${styles.recoverPasswordButtonContainer}`}>
                                <Button
                                    preset={'secondary'}
                                    type='button'
                                    text={t('common.cancel')}
                                    onClick={() =>
                                        setScreen(ScreenType.LOGIN)
                                    }
                                />
                                <Button
                                    preset={'primaryAlt'}
                                    type='submit'
                                    text={t('login.recover_password_button')}
                                />
                            </div>
                        </form>
                    )}
                    {screen === ScreenType.SET_PASSWORD && (
                        <form
                            onSubmit={handleSubmitSetPassword(
                                onSubmitSetPassword
                            )}
                            className={styles.setPasswordForm}
                        >
                            <p className={styles.setPasswordTitle}>{t('login.set_password_title')}</p>
                            <p className={styles.setPasswordSubtitle}>{t('login.set_password_subtitle')}</p>

                            <FormItem className={styles.formItem}>
                                <Label>{t('login.password')}</Label>
                                <Input
                                    preset='login'
                                    type='password'
                                    minLength={5}
                                    placeholder={t('login.password')}
                                    {...registerSetPassword('password', { required: true, minLength: 5 })}
                                />
                                <InputError
                                    error={errorsSetPassword.password}
                                />
                            </FormItem>
                            <FormItem className={styles.formItem}>
                                <Label>{t('login.password_repeat')}</Label>
                                <Input
                                    preset='login'
                                    type='password'
                                    placeholder={t('login.password_repeat')}
                                    {...registerSetPassword('passwordRepeat', {
                                        required: true,
                                        validate: value => value === watchSetPassword('password') ||t('common.errors.password_not_match'),
                                    })}
                                />
                                <InputError
                                    error={errorsSetPassword.passwordRepeat}
                                />
                            </FormItem>

                            <Button
                                type='submit'
                                preset={'primaryAlt'}
                                className={styles.alignRight}
                                text={t('login.change_password_button')}
                            />
                        </form>
                    )}
                </div>
            </div>
        </ScreenTitle>
    );
};

export default RecoverPasswordScreen;
