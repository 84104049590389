/* eslint-disable unused-imports/no-unused-vars */
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { UpdateUserDto } from 'api/users/models/UpdateUserDto';
import Loading from 'common/services/Loading';
import UsersService from 'api/users/UsersService';
import Logger from 'common/services/Logger';
import { useForm } from 'react-hook-form';
import { DEFAULT_EMAIL_RULES_WITH_REQUIRED, DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE } from 'Config';
import { useEffect, useState } from 'react';
import { CheckAvailabilityDto } from 'api/common/models/CheckAvailabilityDto';
import CustomFile from 'common/models/CustomFile';
import { Reducers } from 'store/types';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import { updateRefreshToken } from 'store/authentication/action';
import { useParams } from 'react-router-dom';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import Utils from 'common/services/Utils';
import Box from 'common/components/box/Box';
import { Col, Row } from 'react-flexbox-grid';
import FormItem from 'common/components/formItem/FormItem';
import Label from 'common/components/label/Label';
import Input from 'common/components/input/Input';
import InputError from 'common/components/inputError/InputError';
import MediaSelector from 'common/components/mediaSelector/MediaSelector';
import Button from 'common/components/button/Button';
import styles from './AccountScreen.module.scss';
import DateTimePickerController from 'common/components/dateTimePicker/DateTimePickerController';
import { FaCalendarAlt } from 'react-icons/fa';
import InputGroup from 'common/components/inputGroup/InputGroup';


const AccountScreen = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { type } = useParams<{ type: string }>();
    const [user, setUser] = useState<UpdateUserDto>();
    const form = useForm<UpdateUserDto>({ shouldUnregister: false, defaultValues: { ...user } });
    const { register, handleSubmit, formState: { errors }, watch, reset, setValue } = form;
    const [isDetails, setIsDetails] = useState<boolean>(type == 'details');
    const [mediaFile, setMediaFile] = useState<CustomFile | null>(null);
    const [removeMedia, setRemoveMedia] = useState(false);
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const newPassword = form.watch('newPassword');
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasMyAccountPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_USERS_MY_ACCOUNT']);

    const dispatch = useDispatch();

    const onCancel = () => {
        setIsDetails(true);
        getUser();
    };

    const getUser = async () => {
        Loading.show();

        try {
            const u = await UsersService.getLoggedUser();
            console.log('u', u);

            setUser(u);
            reset(u);
            Loading.hide();
        }
        catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't get user info`,
                error
            );
            Loading.hide();
        }
    }

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        if (Utils.isStringNullOrEmpty(form.watch('newPassword') ?? '')) {
            form.clearErrors();
        }
    }, [form.watch('newPassword')]);

    const onSubmit = async (formData: UpdateUserDto) => {
        console.log('formData.email', formData.email);

        if (user) {
            try {
                if (formData.newPassword && formData.newPassword !== formData.confirmPassword) {
                    addToast(t('account.passwords_doesnt_match'), { appearance: 'warning' });
                    return;
                }

                const emailData: CheckAvailabilityDto = { table: 'asp_net_users', column: 'normalized_email', value: formData.email.toLocaleUpperCase(), companyId: true, itemEdit: loggedUser?.id };
                if (!await UsersService.checkAvailability(emailData)) {
                    addToast(t('common.messages.email_already_exists'), { appearance: 'warning' });
                    return;
                }

                Loading.show();

                user.realName = formData.realName;
                user.email = formData.email;
                user.phoneNumber = formData.phoneNumber;
                user.newPassword = formData.newPassword;
                user.removeMedia = removeMedia;
                user.numBiCc = formData.numBiCc;
                user.numTaxPayer = formData.numTaxPayer;
                user.birthDate = formData.birthDate;
                user.address = formData.address;
                user.zipCode = formData.zipCode;
                user.city = formData.city;
                user.country = formData.country;                


                await UsersService.updateAccount(user, mediaFile);

                user.newPassword = undefined;
                setValue('newPassword', undefined);
                setValue('confirmPassword', undefined);
                dispatch(updateRefreshToken());

                Loading.hide();

                addToast(t('common.messages.record_save_success'), {
                    appearance: 'success',
                });

                onCancel();
            } catch (error) {
                addToast(t('common.messages.record_save_error'), {
                    appearance: 'error',
                });

                Logger.error(
                    LOGGER_LOG_TYPE.REQUEST,
                    `Couldn't create or update user`,
                    error
                );
                Loading.hide();
            }
        }
    };

    const onError = async () => {
        addToast(t('common.errors.required_fields_empty'), { appearance: 'warning' });
    }

    const onAddAvatar = (files: CustomFile[]) => {
        if (files.length > 0) {
            setMediaFile(files[0]);
        }
    }

    const onRemoveAvatar = () => {
        setRemoveMedia(true);
        if (user) {
            setUser({
                ...user,
                media: null,
            });
        }
    }

    const canPermission = (): JSX.Element => {
        return <Box>
            <div>
                <form className={styles.form} onSubmit={handleSubmit(onSubmit, onError)}>
                    <Row>
                        <Col xs>
                            <Row>
                                <Col xs={12}>
                                    <FormItem>
                                        <Label className={styles.label}>
                                            {t('users.name')}*
                                        </Label>
                                        <Input
                                            placeholder={t('users.name')}
                                            defaultValue={user?.realName}
                                            disabled={true}
                                            {...register('realName', { ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                        />
                                        <InputError error={errors.realName} />
                                    </FormItem>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormItem>
                                        <Label className={styles.label}>
                                            {t('users.email')}*
                                        </Label>
                                        <Input
                                            placeholder={t('users.email')}
                                            defaultValue={user?.email}
                                            disabled={true}
                                        />
                                        <InputError error={errors.email} />
                                    </FormItem>
                                </Col>
                                <Col xs={12} md={6}>
                                </Col>
                                
                                <Col xs={12} md={6}>
                                    <FormItem>
                                        <Label className={styles.label}>
                                            {t('users.numBiCc' as any)}
                                        </Label>
                                        <Input
                                            placeholder={t('users.numBiCc')}
                                            defaultValue={user?.numBiCc}
                                            {...register('numBiCc')}
                                            disabled={true}
                                        />
                                        <InputError error={errors.numBiCc} />
                                    </FormItem>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormItem>
                                        <Label className={styles.label}>
                                            {t('users.numTaxPayer' as any)}
                                        </Label>
                                        <Input
                                            placeholder={t('users.numTaxPayer')}
                                            defaultValue={user?.numTaxPayer}
                                            {...register('numTaxPayer')}
                                            disabled={true}
                                        />
                                        <InputError error={errors.numTaxPayer} />
                                    </FormItem>
                                </Col>

                                <Col xs={12} md={6}>
                                    <FormItem>
                                        <Label className={styles.label}>
                                            {t('users.phoneNumber' as any)}
                                        </Label>
                                        <Input
                                            placeholder={t('users.phoneNumber')}
                                            defaultValue={user?.phoneNumber}
                                            {...register('phoneNumber')}
                                            autoComplete={'nome'}
                                            disabled={isDetails}
                                        />
                                        <InputError error={errors.phoneNumber} />
                                    </FormItem>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('users.birthDate')}</Label>
                                        <DateTimePickerController
                                            form={form}
                                            placeholderText={t('users.birthDate')}
                                            customInput={<InputGroup icon={<FaCalendarAlt />} />}
                                            name={'birthDate'}
                                            autoComplete='off'
                                            disabled={isDetails}
                                        />
                                        <InputError error={errors?.birthDate} />
                                    </FormItem>
                                </Col>
                                
                                <Col xs={12}>
                                    <FormItem>
                                        <Label className={styles.label}>
                                            {t('users.address' as any)}
                                        </Label>
                                        <Input
                                            placeholder={t('users.address')}
                                            defaultValue={user?.address}
                                            {...register('address')}
                                            disabled={isDetails}
                                        />
                                        <InputError error={errors.address} />
                                    </FormItem>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormItem>
                                        <Label className={styles.label}>
                                            {t('users.zipCode' as any)}
                                        </Label>
                                        <Input
                                            placeholder={t('users.zipCode')}
                                            defaultValue={user?.zipCode}
                                            {...register('zipCode')}
                                            disabled={isDetails}
                                        />
                                        <InputError error={errors.zipCode} />
                                    </FormItem>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormItem>
                                        <Label className={styles.label}>
                                            {t('users.city' as any)}
                                        </Label>
                                        <Input
                                            placeholder={t('users.city')}
                                            defaultValue={user?.city}
                                            {...register('city')}
                                            disabled={isDetails}
                                        />
                                        <InputError error={errors.city} />
                                    </FormItem>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormItem>
                                        <Label className={styles.label}>
                                            {t('users.country' as any)}
                                        </Label>
                                        <Input
                                            placeholder={t('users.country')}
                                            defaultValue={user?.country}
                                            {...register('country')}
                                            disabled={isDetails}
                                            name='country'
                                        />
                                        <InputError error={errors.country} />
                                    </FormItem>
                                </Col>
                                <Col xs={12} md={6}>
                                </Col>


                                <Col xs={12} md={6} lg={4}>
                                    <FormItem>
                                        <Label className={styles.label}>
                                            {t('users.stam' as any)}
                                        </Label>
                                        <Input
                                            placeholder={t('users.stam')}
                                            defaultValue={user?.stam}
                                            {...register('stam')}
                                            disabled={true}
                                        />
                                        <InputError error={errors.stam} />
                                    </FormItem>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <FormItem>
                                        <Label className={styles.label}>
                                            {t('users.stamFop' as any)}
                                        </Label>
                                        <Input
                                            placeholder={t('users.stamFop')}
                                            defaultValue={user?.stamFop}
                                            {...register('stamFop')}
                                            disabled={true}
                                        />
                                        <InputError error={errors.stamFop} />
                                    </FormItem>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <FormItem>
                                        <Label className={styles.label}>
                                            {t('users.numSocio1' as any)}
                                        </Label>
                                        <Input
                                            placeholder={t('users.numSocio1')}
                                            defaultValue={user?.numSocio1}
                                            {...register('numSocio1')}
                                            disabled={true}
                                        />
                                        <InputError error={errors.numSocio1} />
                                    </FormItem>
                                </Col>

                                {(user?.numSocio1 || user?.numSocio2) &&
                                <>
                                <Col xs={12} md={6} lg={4}>
                                    <FormItem>
                                        <Label className={styles.label}>
                                            {t('users.numSocio2' as any)}
                                        </Label>
                                        <Input
                                            placeholder={t('users.numSocio2')}
                                            defaultValue={user?.numSocio2}
                                            {...register('numSocio2')}
                                            disabled={true}
                                        />
                                        <InputError error={errors.numSocio2} />
                                    </FormItem>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <FormItem>
                                        <Label className={styles.label}>
                                            {t('users.numSocio3' as any)}
                                        </Label>
                                        <Input
                                            placeholder={t('users.numSocio3')}
                                            defaultValue={user?.numSocio3}
                                            {...register('numSocio3')}
                                            disabled={true}
                                        />
                                        <InputError error={errors.numSocio3} />
                                    </FormItem>
                                </Col>
                                </>
                                }

                                {!isDetails &&
                                    <Col xs={12} md={6}>
                                        <FormItem>
                                            <Label className={styles.label}>
                                                {t('account.new_password')}
                                            </Label>
                                            <Input
                                                type='password'
                                                preset='login'
                                                placeholder={t('account.new_password')}
                                                autoComplete={'nome'}
                                                disabled={isDetails}
                                                {...register('newPassword')}
                                            />
                                            <InputError error={errors.newPassword} />
                                        </FormItem>
                                    </Col>
                                }
                                {newPassword &&
                                    <Col xs={12} md={6}>
                                        <FormItem>
                                            <Label className={styles.label}>
                                                {t('account.confirm_new_password')}*
                                            </Label>
                                            <Input
                                                type='password'
                                                preset='login'
                                                placeholder={t('account.confirm_new_password')}
                                                autoComplete={'nome'}
                                                disabled={isDetails}
                                                {...register('confirmPassword'
                                                    , { validate: value => value === newPassword || t('common.errors.password_not_match') }
                                                )}
                                            />
                                            <InputError error={errors.confirmPassword} />
                                        </FormItem>
                                    </Col>
                                }
                            </Row>
                        </Col>
                        {(!isDetails || (isDetails && user?.media)) && <Col md={3} xs={12}>
                            <div className={styles.avatarChooser}>
                                <MediaSelector
                                    isDetails={isDetails}
                                    isMulti={false}
                                    onAddMedias={onAddAvatar}
                                    onRemoveMedia={onRemoveAvatar}
                                    initialMedias={user?.media ? [user?.media] : []}
                                />
                            </div>
                        </Col>}

                    </Row>

                    <div className={styles.buttonContainer}>
                        {!isDetails &&
                            <Button preset={'secondary'}
                                type='button'
                                text={t('common.cancel')}
                                onClick={() => onCancel()}
                            />
                        }

                        {isDetails &&
                            <Button
                                type='button'
                                text={t('common.edit')}
                                onClick={() => setIsDetails(false)}
                            />
                        }
                        {!isDetails &&
                            <Button
                                type='submit'
                                text={t('common.save')}
                            />
                        }
                    </div>
                </form>
            </div>
        </Box>

            ;
    }


    const cannotPermission = (): JSX.Element => {
        return <Box>
            <div>
                <div>{t('common.no_permission')}</div>
            </div>
        </Box >;
    }


    return (
        <ScreenTitle title={t('account.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('account.title')} />
                {hasMyAccountPolicy ? canPermission() : cannotPermission()}
            </ScreenContainer>
        </ScreenTitle>
    );
};

export default AccountScreen;