import { useRef } from 'react';
import DateTimePicker, { Props as DateTimePickerProps } from './DateTimePicker';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';
import moment from 'moment';
import { DATE_FORMAT_DEFAUT } from 'Config';


type Props = {
    form: UseFormReturn<any>;
    name: string;
    onChange?: any;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
} & Omit<DateTimePickerProps, 'onChange'>;

const DateTimePickerController = ({ form, name, rules, onChange, dateFormat, ...props }: Props) => {
    const ref = useRef(null);
    
    return (
        <Controller
            render={({ field }) => {
                return (                    
                        <DateTimePicker
                            {...props}
                            ref={ref}
                            onChange={date => {                                
                                field.onChange(date);
                                if (onChange) {
                                    onChange(date)
                                }
                            }}
                            selected={field.value ? moment(field.value).toDate() : null}
                            value={field.value ? moment(field.value).format(Array.isArray(dateFormat) ? dateFormat[0] : dateFormat ?? DATE_FORMAT_DEFAUT) : undefined}
                        />
                );
            }}
            control={form.control}
            name={name}
            defaultValue={form.getValues(name) ? moment(form.getValues(name)).format(Array.isArray(dateFormat) ? dateFormat[0] : dateFormat ?? DATE_FORMAT_DEFAUT) : undefined}
            rules={rules}
        />
    );
};

export default DateTimePickerController;
