import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';

import { MagazineSearchCriteria } from './models/MagazineSearchCriteria';
import { MagazineDto } from './models/MagazineDto';
import CustomFile from 'common/models/CustomFile';

class MagazinesService {
    public getList(criteria:MagazineSearchCriteria) {
        return Http.get<Paged<MagazineDto>>('magazines', criteria);
    }

    public get(id: string) {
        return Http.get<MagazineDto>(`magazines/${id}`);
    }

    public create(model: MagazineDto, mediaFile: CustomFile[] | null) {
        return Http.postWithFile('magazines', model, mediaFile ? mediaFile : []);
    }

    public update(model: MagazineDto, mediaFile: CustomFile[] | null) {
        return Http.putWithFile('magazines', model, mediaFile ? mediaFile : []);
    }

    public remove(id: string) {
        return Http.put('magazines/deactivate', { id: id });
    }
}

export default new MagazinesService();