import { AlertsTotalsDto } from 'api/alerts/models/AlertsTotalsDto';

export const SET_TOTALS = 'SET_TOTALS';

export type Action = SetTotalsAction;

export interface SetTotalsAction {
    type: typeof SET_TOTALS;
    totals: AlertsTotalsDto;
}

export interface AlertsState {
    totals: AlertsTotalsDto;
}
