import IconNoPhoto from 'assets/svg/default_photo.svg';
import styles from './Avatar.module.scss';

const sizes = {
    x1: 10,
    x2: 20,
    x3: 30,
    x4: 40,
}

export type AvatarProps = {
    size?: 'default' | keyof typeof sizes;
    src: string | undefined | null;
    fw?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Avatar = ({ size, src, fw, ...props }: AvatarProps) => {
    return (
        <div
            {...props}
            className={`${styles.container} ${props.className || ''}`}
            style={{
                width: size && size !== 'default' ? sizes[size] : '100%',
                height: size && size !== 'default' ? sizes[size] : '100%',
                backgroundImage: `url('${src || IconNoPhoto}')`,
                marginRight: fw ? '0.5rem' : undefined,
                ...(props.style || {}),
            }}
        />
    );
}

export default Avatar;