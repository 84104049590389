import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import Box from 'common/components/box/Box';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Reducers } from 'store/types';
import { LOGGER_LOG_TYPE, Types } from 'Config';
import { Col, Row } from 'react-flexbox-grid';
import Label from 'common/components/label/Label';
import styles from './FolderScreen.module.scss';
import { useFieldArray, useForm } from 'react-hook-form';
import InputError from 'common/components/inputError/InputError';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import Input from 'common/components/input/Input';
import Button from 'common/components/button/Button';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { FolderDto, ServerFolderDto } from 'api/folders/models/FolderDto';
import FoldersService from 'api/folders/FoldersService';
import ServersService from 'api/servers/ServersService';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import Select from 'common/components/select/Select';
import IconTrash from 'assets/svg/trash.svg';
import DateTimePickerController from 'common/components/dateTimePicker/DateTimePickerController';
import InputGroup from 'common/components/inputGroup/InputGroup';
import { FaCalendarAlt } from 'react-icons/fa';
import moment from 'moment';
import Tabs, { TabItem } from 'common/components/tabs/Tabs';
import AlertTab from 'screens/servers/tabs/alertTab/AlertTab';
import ServerFolderTab from './tabs/serverFolderTab/ServerFolderTab';

type Props = {
};

const FolderScreen: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const { folderId, type } = useParams<{ folderId: string, type: Types }>();
    const isDetails = type === 'details';
    const isNew = type === 'new';
    const history = useHistory();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVerificationsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_FOLDERS_WRITE']);

    const form = useForm<FolderDto>();
    const { control, register, handleSubmit, formState: { errors }, reset, watch } = form;

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'serverFolder',
        keyName: 'formId'
    });

    const [folder, setFolder] = useState<FolderDto | null>(null);
    const [serverList, setServerList] = useState<SelectValueLabel[] | null>(null);
    const [selectedServer, setSelectedServer] = useState<SelectValueLabel | null>(null);

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [showRemoveServerModal, setShowRemoveServerModal] = useState<boolean>(false);
    const [selectedConnection, setSelectedConnection] = useState<ServerFolderDto | null>(null);

    const getData = async () => {
        try {
            if (!folderId) return;
            Loading.show();

            const result = await FoldersService.get(folderId);
            setFolder(result);
            reset({
                id: result.id,
                name: result.name,
                createdDate: result.createdDate
            });

            if (!!result.serverFolder) {
                append(result.serverFolder)
            }
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const getServerCatalog = async () => {
        try {
            Loading.show();
            const result = await ServersService.catalog();
            setServerList(result);
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const onSubmit = async (formData: FolderDto) => {
        try {
            Loading.show();
            if (isNew) {
                const id = await FoldersService.create(formData);
                history.push(`/folders/details/${id}`);
            } else {
                await FoldersService.update(formData);
                history.push(`/folders/details/${folderId}`);
            }

            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't create or update servers`, error);
            Loading.hide();
        }
    };

    useEffect(() => {
        getServerCatalog();
    }, []);

    useEffect(() => {
        reset(undefined);
        getData();
    }, [type, folderId]);

    const onCancel = () => {
        history.push(isDetails || isNew ? '/folders' : `/folders/details/${folderId}`);
    }

    const navigateToEdit = () => {
        history.push(`/folders/edit/${folderId}`)
    }

    const onCancelRemove = () => {
        setShowRemoveModal(false);
    }

    const onCancelRemoveServer = () => {
        setShowRemoveServerModal(false);
    }

    const onRemove = async () => {
        try {
            Loading.show();
            setShowRemoveModal(false);
            await FoldersService.remove(folderId);
            history.push('/folders');
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't remove the folder`, error);
            Loading.hide();
        }
    }

    const onRemoveServer = async () => {
        if (!selectedConnection) return;
        setShowRemoveModal(false);
        const index = fields.findIndex(x => x.formId === (selectedConnection as any).formId);
        remove(index);
        setSelectedConnection(null);
        setShowRemoveServerModal(false);
    }

    const addServerToForm = () => {
        if (!selectedServer) return;
        const model: ServerFolderDto = {
            serverId: selectedServer.value,
            serverName: selectedServer.label
        };
        append(model);
        setSelectedServer(null);
    }

    const tabs: TabItem[] = useMemo(() => {
        const items: TabItem[] = [{
            id: 'alerts',
            title: t('alerts.title'),
            badge: (folder?.alertList ?? []).filter(x => x.isRead === false).length,
            content: <AlertTab alerts={folder?.alertList ?? []} reload={getData} />
        }];

        if (!folder || !folder.serverFolder?.length) return items;

        for (let i = 0; i < folder.serverFolder.length; i++) {
            const sp = folder.serverFolder[i];
            items.push({
                id: `serverFolder-${i}`,
                title: `${sp.serverName}`,
                content: <ServerFolderTab serverFolder={sp} />
            })
        }

        return items;
    }, [folder?.serverFolder, folder?.alertList]);

    const [tabId, setTabId] = useState<string>('serverFolder-0');

    const canCreate = (): JSX.Element => {
        return <Box>
            <form onSubmit={handleSubmit(onSubmit)} >
                <div className={styles.container}>
                    <Row className={styles.rowSpace}>
                        <Col xs={12}>
                            <Label className={styles.label}>{t('servers.details.name')} *</Label>
                            <Input
                                placeholder={t('servers.details.name')}
                                defaultValue={folder?.name}
                                {...register('name', { required: true })}
                                disabled={isDetails}
                            />
                            <InputError error={errors?.name} />
                        </Col>
                    </Row>
                    {!!isDetails && <Row className={styles.rowSpace}>
                        <Col xs={12}>
                            <div className={styles.tabContainer}>
                                <Tabs
                                    items={tabs}
                                    activeTabId={tabId}
                                    onChange={setTabId}
                                />
                            </div>
                        </Col>
                    </Row>}
                    {!isDetails && <Row className={styles.rowSpace}>
                        <Col xs={12}>
                            <Label className={styles.label}>{t('folders.details.serverList')} *</Label>
                        </Col>
                        <Col xs={6}>
                            <Select
                                options={serverList ?? []}
                                value={selectedServer}
                                onChange={(newValue: unknown) => {
                                    setSelectedServer(newValue as SelectValueLabel);
                                }}
                                isDisabled={false}
                            />
                        </Col>
                        <Col xs={6}>
                            <Button
                                type='button'
                                text={t('common.add')}
                                onClick={() => addServerToForm()}
                            />
                        </Col>

                        {!!fields && fields.length > 0 && watch('serverFolder') && fields.map((connection, index: number) => {
                            return <Col key={connection.formId} xs={12} md={6}>
                                <div className={styles.fields}>
                                    <div>
                                        <span>{t('folders.details.serverName')}:</span>
                                        <span style={{ marginLeft: '0.5rem' }}>{connection.serverName}</span>
                                    </div>

                                    <div className={styles.marginTop}>
                                        <Label className={styles.label}>{t('folders.details.last_sync')}</Label>
                                        <DateTimePickerController
                                            form={form}
                                            placeholderText={t('folders.details.last_sync')}
                                            onChange={(lastSync: Date) => { console.log(lastSync) }}
                                            selected={folder != undefined && folder.serverFolder != undefined && folder.serverFolder[index] != undefined && folder.serverFolder[index].lastSync ? moment(folder.serverFolder[index].lastSync).toDate() : null}
                                            customInput={<InputGroup icon={<FaCalendarAlt />} />}
                                            name={`serverFolder.${index}.lastSync`}
                                            autoComplete='off'
                                            disabled={true} />

                                        <InputError error={!!errors?.serverFolder ? errors?.serverFolder[index]?.lastSync : undefined} />
                                    </div>
                                    <div className={styles.marginTop}>
                                        <Label className={styles.label}>{t('folders.details.frequency')}*</Label>
                                        <Input
                                            placeholder={t('folders.details.frequency')}
                                            defaultValue={folder != undefined && folder.serverFolder != undefined && folder.serverFolder[index] != undefined ? folder.serverFolder[index].frequency : ''}
                                            {...register(`serverFolder.${index}.frequency`, { required: true })}
                                            disabled={isDetails}
                                            type={'number'}
                                        />
                                        <InputError error={!!errors?.serverFolder ? errors?.serverFolder[index]?.frequency : undefined} />
                                    </div>


                                    {!isDetails && <div
                                        className={styles.removeButton}
                                        onClick={() => {
                                            setShowRemoveServerModal(true);
                                            setSelectedConnection(connection);
                                        }}>
                                        <img
                                            src={IconTrash}
                                            className={styles.icon}
                                            alt={'remove logo'} />
                                    </div>}
                                </div>
                            </Col>
                        })}
                    </Row>}
                </div>
                <QuestionYesNo
                    onNo={onCancelRemove}
                    onYes={onRemove}
                    isVisible={showRemoveModal}
                    message={t('common.messages.remove_record_with_ident', { name: folder?.name ?? '' })} />
                <QuestionYesNo
                    onNo={onCancelRemoveServer}
                    onYes={onRemoveServer}
                    isVisible={showRemoveServerModal}
                    message={t('common.messages.remove_record')} />
                <div className={styles.buttonContainer}>
                    {isDetails &&
                        <>
                            <Button
                                preset={'secondary'}
                                type='button'
                                text={t('common.cancel')}
                                onClick={() => onCancel()}
                            />
                            <Button
                                preset={'danger'}
                                type='button'
                                text={t('common.remove')}
                                onClick={() => setShowRemoveModal(true)}
                            />
                            <Button
                                type='button'
                                text={t('common.edit')}
                                onClick={() => navigateToEdit()}
                            />
                        </>
                    }
                    {!isDetails &&
                        <>
                            <Button preset={'secondary'}
                                type='button'
                                text={t('common.cancel')}
                                onClick={() => onCancel()}
                            />
                            <Button
                                type='submit'
                                text={t('common.save')}
                            />
                        </>
                    }
                </div>
            </form>
        </Box >;
    }

    const cannotCreate = (): JSX.Element => {
        return <Box>
            <div className={styles.permissionContainer}>
                <div>{t('common.no_permission')}</div>
                <Button
                    preset={'secondary'}
                    type='button'
                    text={t('common.cancel')}
                    onClick={() => onCancel()}
                />
            </div>
        </Box>;
    }

    return (
        <ScreenTitle title={t('folders.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('folders.title')} />
                {hasVerificationsWritePolicy ? canCreate() : cannotCreate()}
            </ScreenContainer>
        </ScreenTitle>
    );
};

export default FolderScreen;
