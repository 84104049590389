import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { AssociationDuesDto } from 'api/associationDues/models/AssociationDuesDto';
import Loading from 'common/services/Loading';
import AssociationDuesService from 'api/associationDues/AssociationDuesService';
import Logger from 'common/services/Logger';
import Button from 'common/components/button/Button';
import styles from './AssociationDuesDetails.module.scss';
import InputError from 'common/components/inputError/InputError';
import Input from 'common/components/input/Input';
import Label from 'common/components/label/Label';
import FormItem from 'common/components/formItem/FormItem';
import { useForm } from 'react-hook-form';
import { DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE, removeAccents } from 'Config';
import GreyArrow from 'assets/svg/Arrow-grey.svg';
import React, { useEffect, useState } from 'react';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'react-flexbox-grid';
import { FaCalendarAlt } from 'react-icons/fa';
import DateTimePickerController from 'common/components/dateTimePicker/DateTimePickerController';
import InputGroup from 'common/components/inputGroup/InputGroup';
import moment from 'moment';
import AssociationsService from 'api/associations/AssociationsService';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import InputGroupController from 'common/components/inputGroup/InputGroupController';
import SelectController from 'common/components/select/SelectController';

const AssociationDuesDetails = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const { id: associationDuesId, type } = useParams<{ id: string, type: string }>();
    const [associationDues, setAssociationDues] = useState<AssociationDuesDto>();
    const isDetails = type === 'details';
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const history = useHistory();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasAssociationsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ASSOCIATIONS_WRITE']);
    const form = useForm<AssociationDuesDto>({ shouldUnregister: false, defaultValues: { ...associationDues } });

    const { setValue, register, handleSubmit, formState: { errors }, reset } = form;
    const [allAssociations, setAllAssociations] = useState<SelectValueLabel[]>([]);
    const [allAssociateTypes, setAllAssociateTypes] = useState<SelectValueLabel[]>([]);

    const getData = async () => {
        try {
            const options = await AssociationsService.getAll();
            const associationsList = options.map(item => ({ value: item.id, label: item.name }));
            setAllAssociations(associationsList);

            const a: AssociationDuesDto = {
                id: ''
                , name: ''
                , startDate: null
                , endDate: null
                , value: 0
                , system: false
                , readOnly: false
                , association: undefined
                , associatesType: undefined
            };
            if (!!associationDuesId) {
                Loading.show();
                const result: AssociationDuesDto = await AssociationDuesService.getById(associationDuesId);
                a.id = result.id;
                a.name = result.name;
                a.startDate = result.startDate;
                a.endDate = result.endDate;
                a.value = result.value;
                if (result.association) {
                    a.selectedAssociation = { value: result.association.id, label: result.association.name };
                    a.associationId = result.association.id;
                    getAllAssociatesTypeByAssociationId(result.association.id);
                }
                if (result.associatesType && result.associatesType.id) {
                    a.selectedAssociatesType = { value: result.associatesType.id, label: result.associatesType.name };
                    a.associatesTypeId = result.associatesType.id;
                }

            }
            setAssociationDues(a);
            reset(a);
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.ASSOCIATIONDUES,
                `Couldn't get catalog associationDues`,
                error
            );
            Loading.hide();
        }
    }

    useEffect(() => {
        getData();
    }, [isDetails]);


    const goToList = () => {
        history.push(`/security/associationdues`);
    };

    const onCancel = () => {
        if (isDetails) {
            history.push('/security/associationdues');
        } else if (!associationDuesId) {
            history.goBack();
        } else {
            getData();
            history.push(`/security/associationdues/details/${associationDuesId}`);
        }
    };

    const onCancelRemove = () => {
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        console.log('remove ')

        try {

            if (associationDues) {
                Loading.show();
                setShowRemoveModal(false);
                await AssociationDuesService.remove(associationDues);
                history.push('/security/associationdues');
                Loading.hide();
            }

        } catch (error) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.ASSOCIATIONDUES, `Couldn't remove the association dues`, error);
            Loading.hide();
        }
    };

    const getAllAssociatesTypeByAssociationId = async (selectedId: string) => {
        try {
            const associatesTypes = await AssociationsService.getAllAssociatesTypeByAssociationId(selectedId);
            const associatesTypesList = associatesTypes.map(item => ({ value: item.id ? item.id : '', label: item.name }));
            setAllAssociateTypes(associatesTypesList);

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.ASSOCIATIONDUES, `Couldn't get associates type`, error);
            Loading.hide();
        }
    };


    const onSubmit = async (formData: AssociationDuesDto) => {
        try {
            if (!hasAssociationsWritePolicy) { return; }

            Loading.show();
            if (associationDues) {
                associationDues.id = formData.id;
                associationDues.name = formData.name;
                associationDues.startDate = formData.startDate;
                associationDues.endDate = formData.endDate;
                associationDues.value = formData.value;
                associationDues.associatesTypeId = formData.associatesTypeId;
            }

            if (associationDues && associationDues.id != '') {
                await AssociationDuesService.update(associationDues);
            } else if (associationDues) {
                await AssociationDuesService.create(associationDues);
            }

            Loading.hide();
            addToast(t('common.messages.record_save_success'), {
                appearance: 'success',
            });
            goToList();
        } catch (error) {
            addToast(t('common.messages.record_save_error'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.ASSOCIATIONDUES,
                `Couldn't create or update association dues`,
                error
            );
            Loading.hide();
        }
    };

    const showRemoveItemDialog = async () => {
        setShowRemoveModal(true);
    }


    return (
        <div>
            <div className={styles.pageNameContainer}>
                <div className={styles.pageNameContent} onClick={() => goToList()} >
                    <img className={styles.pageNameContentImage} src={GreyArrow} />
                    <span className={styles.pageNameContentText}>
                        {t('association_dues.title')}
                    </span>
                </div>
            </div>

            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>

                <Row>
                    <Col xs={12} md={12}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('association_dues.details.name')}*
                            </Label>
                            <Input
                                placeholder={t('association_dues.details.name')}
                                defaultValue={associationDues?.name}
                                {...register('name', { ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                disabled={isDetails}
                            />
                            <InputError error={errors.name} />
                        </FormItem>
                    </Col>

                    <Col xs={12} sm={12} md={6} xl={4}>
                        <FormItem>
                            <Label className={styles.label}>{t('association_dues.details.association')} *</Label>
                            <SelectController
                                form={form as any}
                                name='associationId'
                                options={allAssociations}
                                placeholder={t('association_dues.details.association')}
                                isDisabled={isDetails}
                                rules={{ required: true }}
                                isClearable={true}
                                filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                onChangeSelect={(data: SelectValueLabel) => {
                                    setValue('associationId', data ? data.value : null);
                                    getAllAssociatesTypeByAssociationId(data.value);
                                }}
                            />
                            <InputError error={errors.associationId} />
                        </FormItem>
                    </Col>

                    <Col xs={12} sm={12} md={6} xl={4}>
                        <FormItem>
                            <Label className={styles.label}>{t('association_dues.details.associate_type')} *</Label>
                            <SelectController
                                form={form as any}
                                name='associatesTypeId'
                                options={allAssociateTypes}
                                placeholder={t('association_dues.details.associate_type')}
                                isDisabled={isDetails}
                                rules={{ required: true }}
                                isClearable={true}
                                filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                onChangeSelect={(data: SelectValueLabel) => {
                                    setValue('associatesTypeId', data ? data.value : null);
                                }}
                            />
                            <InputError error={errors.associatesTypeId} />
                        </FormItem>
                    </Col>

                    <Col xs={12} sm={12} md={6} xl={4}>
                        <FormItem>
                            <Label className={styles.label}>{t('association_dues.details.value')}</Label>
                            <InputGroupController
                                maxLength={255}
                                type="number"
                                text={t('common.euro')}
                                placeholder={t('association_dues.details.value')}
                                name="value"
                                form={form}
                                rules={{ maxLength: 255 }}
                                disabled={isDetails}
                                format="money"
                                defaultValue={associationDues?.value || undefined} />
                            {form.formState.isSubmitted && !form.getValues('value')
                                && <label>
                                    {t('common.errors.required')}
                                </label>}
                        </FormItem>
                    </Col>
                    <Col xs={12} sm={12} md={6} xl={4}>
                        <div >
                            <Label className={styles.label}>{t('association_dues.details.start_date')}</Label>
                            <DateTimePickerController
                                form={form}
                                placeholderText={t('association_dues.details.start_date')}
                                onChange={(newDate: Date) => {
                                    setValue('startDate', newDate);
                                }}
                                selected={associationDues != undefined && associationDues.startDate ? moment(associationDues.startDate).toDate() : null}
                                customInput={<InputGroup icon={<FaCalendarAlt />} />}
                                name={'startDate'}
                                autoComplete='off'
                                disabled={isDetails} />

                            <InputError error={errors?.startDate} />
                        </div>
                    </Col>

                    <Col xs={12} sm={12} md={6} xl={4}>
                        <div >
                            <Label className={styles.label}>{t('association_dues.details.end_date')}</Label>
                            <DateTimePickerController
                                form={form}
                                placeholderText={t('association_dues.details.end_date')}
                                onChange={(newDate: Date) => {
                                    setValue('endDate', newDate);
                                }}
                                selected={associationDues != undefined && associationDues.endDate ? moment(associationDues.endDate).toDate() : null}
                                customInput={<InputGroup icon={<FaCalendarAlt />} />}
                                name={'endDate'}
                                autoComplete='off'
                                disabled={isDetails} />

                            <InputError error={errors?.endDate} />
                        </div>
                    </Col>
                </Row>


                <div className={styles.buttonContainer}>
                    <Button preset={'secondary'}
                        type='button'
                        text={t('common.cancel')}
                        onClick={() => onCancel()} />

                    {isDetails && hasAssociationsWritePolicy &&
                        <Button
                            type="button"
                            text={t('common.remove')}
                            preset={'danger'}
                            onClick={() => showRemoveItemDialog()} />
                    }
                    {isDetails && hasAssociationsWritePolicy &&
                        <Button
                            type='button'
                            text={t('common.edit')}
                            onClick={() => history.push(`/security/associationdues/edit/${associationDuesId}`)} />
                    }
                    {!isDetails && hasAssociationsWritePolicy &&
                        <Button
                            type='submit'
                            text={t('common.save')} />
                    }
                </div>

            </form>
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_record_with_ident', { name: 'aaaaqui' })}></QuestionYesNo>
        </div>
    );
};

export default AssociationDuesDetails;
