import React, { useRef, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import styles from './ColorPicker.module.scss';

type Props = {
  value: string;
  onChangeColor: (color: string) => void;
  onOpenPallete: (open: boolean) => void;
};

const ColorPicker: React.FC<Props> = ({ value, onChangeColor, onOpenPallete }: Props) => {

  const [color, setColor] = useState<string>(value);
  const pickerRef = useRef<any>();

  const changeColor = (data: ColorResult) => {
    setColor(data.hex);
    onChangeColor && onChangeColor(data.hex);
  };

  const onClickOutside = () => {
    onOpenPallete(false);
  }

  return (
    <>
      <div className={styles.overlay} onClick={onClickOutside}></div>
      <div style={{ position: 'absolute', zIndex: 399 }}>
        <SketchPicker
          color={color}
          onChange={changeColor}
          ref={pickerRef} />
      </div>
    </>
  );
}

export default ColorPicker;