import React, { ReactChild } from 'react';
import styles from './Dropdown.module.scss';
import DropdownWrapper from 'react-dropdown-wrapper';
import { DropdownWrapperChildFunc } from '../../types/ReactDropdownWrapper';

type Props = {
    children: ReactChild | ReactChild[];
    options: React.ReactNode | React.ReactNode[];
};

const Dropdown: React.FC<Props> = ({ children, options }: Props) => {

    return (
        <DropdownWrapper
            closeOnOutsideClick >
            {({ changeStatus, isShow }: DropdownWrapperChildFunc) => (
                <>
                    <div className={styles.icon} onClick={e => {
                        e.preventDefault();
                        changeStatus(!isShow);
                    }}>{children}</div>
                    {isShow && (
                        <div className={styles.dropdownContainer}>
                            {options}
                        </div>
                    )}
                </>
            )}
        </DropdownWrapper>
    );
}

export default Dropdown;
