import { CSSProperties, ReactElement, useState } from 'react';
import styles from './ListingTable.module.scss';
import { Property } from 'csstype'
import DateFormat from '../dateFormat/dateFormat';
import { useTranslation } from 'react-i18next';
import MoneyFormat from '../moneyFormat/MoneyFormat';
import { isTablet } from 'Config';
import { FaSort } from 'react-icons/fa';
import { FaSortDown } from 'react-icons/fa';
import { FaSortUp } from 'react-icons/fa';

export interface TableRow {
    [field: string]: any;
}

export type ListingTableCellFormat = 'default' | 'money' | 'date';

export interface ListingTableColumn<TRow> {
    field?: keyof TRow;
    name?: string;
    renderCell?: (row: TRow, column: ListingTableColumn<TRow>, index: number) => ReactElement;
    cellAlignment?: Property.TextAlign;
    cellStyle?: CSSProperties;
    columnCellClassName?: string;
    columnCellAlignment?: Property.TextAlign;
    width?: string | number;
    preventClick?: boolean;
    cellFormat?: ListingTableCellFormat;
    cellFormatUnitByField?: string;
    hideOn?: string[]; //'sm','md','lg','xl'
    onSearch?: (field: string, isFilterAsc: boolean) => void;
    searchField?: string;
}

interface Props<TRow> {
    columns: ListingTableColumn<TRow>[];
    rows: TRow[];
    allowHover?: boolean;
    onRowClick?: (row: TRow, index: number, event: any) => void;
    striped?: boolean;
    selectedRow?: TRow | null;
    initialSearch?: SearchItem | null;
    showErrors?: boolean;
    errors?: any[];
    rowStyle?: (row: TRow, col: ListingTableColumn<TRow>, rowIndex: number, colIndex: number) => CSSProperties;
    className?: string;
    headerClassName?: string;
}

export interface SearchItem {
    colField: string;
    isOrderAsc: boolean;
}

const ListingTable = <TRow extends TableRow>({
    columns, rows, selectedRow, allowHover = false, striped = false, className, headerClassName,
    initialSearch = null, showErrors = false, errors = [], onRowClick, rowStyle
}: Props<TRow>) => {

    const { t } = useTranslation();

    const getCellWidthStyle = (col: ListingTableColumn<TRow>) => {
        if (!col.width) {
            return {};
        }

        return { width: col.width };
    }

    const getColumnCellStyle = (col: ListingTableColumn<TRow>) => {
        return {
            textAlign: col.columnCellAlignment || 'left',
            ...getCellWidthStyle(col),
        }
    }

    const getRowCellStyle = (col: ListingTableColumn<TRow>, row: TRow, rowIndex: number, colIndex: number): CSSProperties => {
        return {
            textAlign: col.cellAlignment || 'left',
            ...getCellWidthStyle(col),
            ...(col.cellStyle || {}),
            ...(rowStyle ? rowStyle(row, col, rowIndex, colIndex) : {}),
        }
    }

    const getRowCellValue = (row: TRow, col: ListingTableColumn<TRow>, index: number) => {
        if (col.renderCell) {
            return col.renderCell(row, col, index);
        }

        if (!col.field) {
            return '';
        }

        const value = row[col.field];

        if (col.cellFormat && col.cellFormat === 'money') {
            return <MoneyFormat value={value} suffix={col.cellFormatUnitByField ? row[col.cellFormatUnitByField] : null} />;
        }

        if (col.cellFormat && col.cellFormat === 'date') {
            return <DateFormat value={value} />;
        }

        return value;
    }

    const onClick = (event: any, row: TRow, col: ListingTableColumn<TRow>, index: number) => {
        if (col.preventClick) {
            return;
        }

        if (onRowClick) {
            onRowClick(row, index, event);
        }
    }

    const onSearch = (col: ListingTableColumn<TRow>) => {
        if (col.onSearch) {
            if (!isSearchActive) {
                col.onSearch(col.searchField ?? '', true);
                setIsSearchActive({ colField: col.searchField ?? '', isOrderAsc: true });
            } else {
                col.onSearch(col.searchField ?? '', isSearchActive.colField === col.searchField ? !isSearchActive.isOrderAsc : true);
                setIsSearchActive({ colField: col.searchField ?? '', isOrderAsc: isSearchActive.colField === col.searchField ? !isSearchActive.isOrderAsc : true });
            }
        }
    }

    const [isSearchActive, setIsSearchActive] = useState<SearchItem | null>(initialSearch);

    const isMobile = isTablet(window.innerWidth);

    const renderActiveFilterIcon = () => {
        return <div>            
            {isSearchActive && !isSearchActive.isOrderAsc && <FaSortUp className={`${styles.icon} ${styles.headerInfoImage}`} />}
            {isSearchActive && isSearchActive.isOrderAsc && <FaSortDown className={`${styles.icon} ${styles.headerInfoImage}`} />}
        </div>
    }

    const renderHasFilterIcon = (col: ListingTableColumn<TRow>) => {
        return <div>
            {col.onSearch && <FaSort className={`${styles.icon} ${styles.headerInfoImage}`} />}
        </div>
    }

    return (
        <table className={`${styles.table} ${className || ''}`}>
            <thead>
                <tr>
                    {columns.map((col, colIndex) => (
                        <th key={`col-${colIndex}`} className={`${!col.hideOn ? '' : 
                                col.hideOn?.includes('xl') ? styles.xl : col.hideOn?.includes('lg') ? styles.lg 
                                : col.hideOn?.includes('md') ? styles.md : col.hideOn?.includes('sm') ? styles.sm : ''} 
                            ${styles.columnHeader} ${headerClassName}
                            ${col.onSearch && styles.clicakble}
                            ${col.columnCellClassName || ''}
                            `} style={getColumnCellStyle(col)}
                            onClick={() => onSearch(col)}>
                            <span className={styles.headerInfo}>
                                {col.name} {isSearchActive && isSearchActive.colField === col.searchField ? renderActiveFilterIcon() : renderHasFilterIcon(col)}
                            </span>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows.map((row, rowIndex) => (
                    <tr key={`row-${rowIndex}`} style={{ boxShadow: showErrors && errors[rowIndex] != null ? 'rgb(255 0 0) 0px 0px 0px 2px inset' : '' }} className={`${allowHover && styles.rowHover} ${(selectedRow && (selectedRow.id === row.id || selectedRow.index === row.index)) ? styles.selected : ''}`}>
                        {columns.map((col, colIndex) => (
                            <td
                                key={`col-${colIndex}`}
                                onClick={event => onClick(event, row, col, rowIndex)}
                                className={` ${col.hideOn && col.hideOn?.includes('xl') ? styles.xl : col.hideOn && col.hideOn?.includes('lg') ?
                                    styles.lg : col.hideOn && col.hideOn?.includes('md') ? styles.md : col.hideOn && col.hideOn?.includes('sm') ?
                                        styles.sm : ''} 
                                    ${styles.bodyColumn} ${striped && rowIndex % 2 === 0 ? styles.striped : ''}
                                    ${isMobile ? styles.mobile : ''}`}
                                style={getRowCellStyle(col, row, rowIndex, colIndex)}
                            >
                                {getRowCellValue(row, col, rowIndex)}
                            </td>
                        ))}

                    </tr>
                ))}
            </tbody>
            {Boolean(rows.length <= 0) && <tfoot>
                <tr>
                    <td colSpan={columns.length} className={styles.noItemsText}>
                        {t('common.no_results')}
                    </td>
                </tr>
            </tfoot>}
        </table>
    );
}

export default ListingTable;