import React, { memo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import styles from './ServerFolderTab.module.scss';
import { ServerFolderDto } from 'api/folders/models/FolderDto';
import DateFormat from 'common/components/dateFormat/dateFormat';

type Props = {
    serverFolder: ServerFolderDto;
}

const ServerFolderTab: React.FC<Props> = ({ serverFolder }: Props) => {
    const { t } = useTranslation();
    return (
        <>
            <Row>
                <Col className={styles.marginBottom} xs={12}>
                    <div className={styles.container}>
                        <div>
                            {t('projects.details.serverName')}: 
                        </div>
                        <div className={styles.title}>{serverFolder.serverName}</div>
                    </div>
                </Col>
                <Col className={styles.marginBottom} xs={12} md={6}>
                    <div className={styles.container}>
                        <span>{t('folders.details.last_sync')}:</span>
                        {!!serverFolder.lastSync ?
                            <DateFormat className={styles.title} value={serverFolder.lastSync ?? ''} />
                            : <span className={styles.title}>{t('folders.details.no_last_sync')}</span>}
                    </div>
                </Col>
                <Col className={styles.marginBottom} xs={12} md={6}>
                    <div className={styles.container}>
                        <div>
                            {t('folders.details.frequency')}:
                        </div>
                        <div className={styles.title}>{serverFolder.frequency}</div>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default memo(ServerFolderTab);