 import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
 import { AssociationSearchCriteria } from './models/AssociationSearchCriteria';
 import { AssociationDto } from './models/AssociationDto';
import CustomFile from 'common/models/CustomFile';
import { AssociatesTypeDto } from 'api/associatesType/models/AssociatesTypeDto';

class AssociationService {

   
    public getList(criteria: AssociationSearchCriteria) {
        return Http.get<Paged<AssociationDto>>('associations', criteria);
    }

    public getById(id: string) {
        return Http.get<AssociationDto>('associations/' + id);
    }

    public create(model: AssociationDto, mediaFile: CustomFile | null) {
        return Http.postWithFile('associations', model, mediaFile ? [mediaFile] : []);
    }

    public update(model: AssociationDto, mediaFile: CustomFile | null) {
        return Http.putWithFile('associations', model, mediaFile ? [ mediaFile ] : []);
    }

    public remove(model: AssociationDto) {
        return Http.put('associations/deactivate', model);
    }
    
    public getAll() {
        return Http.get<AssociationDto[]>('associations/all');
    }
        
    public getAllWithoutSystem() {
        return Http.get<AssociationDto[]>('associations/all-without-system');
    }

    //#region Associates Type
    public getAllAssociatesTypeByAssociationId(id : string){
        return Http.get<AssociatesTypeDto[]>('associations/all-associates-types-by-association/'+ id);
    }
    //#endregion

}

export default new AssociationService();