import React, {
    Component
} from 'react';
import PropTypes from 'prop-types'


class Canvas extends Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.imageRef = React.createRef();
    }

    
    componentDidMount() {
        const viewport_convert = (px = 0, vw = 0, vh = 0) => {         
            if(px != 0){
                if(vw){
                    return (100 * px / window.innerWidth);
                } else {
                    return (100 * px / window.innerHeight);
                }
            } else if(vw != 0 && vh != 0){
                var w_h_arr = [];
                w_h_arr['width'] = Math.ceil((window.innerWidth * vw / 100));
                w_h_arr['height'] = Math.ceil((window.innerHeight * vh / 100));
                return w_h_arr;
            } else if(vw != 0){
                return Math.ceil((window.innerWidth * vw / 100));
            } else if(vh != 0){
                return Math.ceil((window.innerHeight * vh / 100));
            }
        }


        const canvas = this.canvasRef
        const ctx = canvas.current.getContext('2d');
        const catImage = new Image();

        ctx.canvas.oncontextmenu = function () {
            return false
        };
        catImage.src = this.props.imageToShow;
        var waterMark = this.props.waterMark;
        
        catImage.onload = function () {            
            ctx.canvas.width = catImage.width;
            ctx.canvas.height = catImage.height;
            ctx.drawImage(catImage, 0, 0);

            ctx.font = '6vh Arial';
            ctx.translate(20, ctx.canvas.height/2);
            ctx.rotate(-Math.PI / 2);
            ctx.fillText(waterMark, -ctx.measureText(waterMark).width/2, viewport_convert(0,0,6));
            ctx.restore();
            ctx.setTransform(1, 0, 0, 1, 0, 0); // restore the transform to default
            
             //Draw user-entered words on canvas
             ctx.globalAlpha=0.5;
             // setup text for filling
             ctx.font = '12vh Comic Sans MS';
             ctx.fillStyle = 'lightgray';
             // get the metrics with font settings
             var metrics = ctx.measureText(waterMark);
             var width = metrics.width;
             // height is font size
             var height = viewport_convert(0,0,12);
             
             
             // change the origin coordinate to the middle of the context
             //ctx.translate(ctx.canvas.width/2, ctx.canvas.height/2);
             // rotate the context (so it's rotated around its center)
             //ctx.rotate(-Math.atan(ctx.canvas.height/ctx.canvas.width));
             // as the origin is now at the center, just need to center the text
             //ctx.fillText(waterMark,-width/2,height/2);
             
             for (let i = 0; i < 20; i++) {
                ctx.rotate ((- 45 * Math.PI) / 180); // initial deflection angle of watermark                
                ctx.font = '12vh Comic Sans MS';
                ctx.fillStyle = 'lightgray';
                ctx.fillText(waterMark,-width/2,i * height*3);
                ctx.rotate ((45 * Math.PI) / 180); // adjust the deflection angle of the watermark to the original, otherwise it will rotate all the time
              }                        
             
        }
    }
   


    render() {
        return (             
            <div>
                <canvas style = { { maxWidth: '100%' } } ref = { this.canvasRef } />                
            </div>
        )
    }
}

Canvas.propTypes = {
    imageToShow: PropTypes.string,
    waterMark: PropTypes.string
};

export default Canvas;