import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './UsersFiltersScreen.module.scss';
import Button from '../../../../../../common/components/button/Button';
import FormItem from '../../../../../../common/components/formItem/FormItem';
import { Col, Row } from 'react-flexbox-grid';
import { SelectValueLabel } from '../../../../../../common/types/SelectValueLabel';
import { Controller, useForm } from 'react-hook-form';
import RolesService from 'api/roles/RolesService';
import Label from 'common/components/label/Label';
import { removeAccents } from 'Config';
import Select from 'common/components/select/Select';
import { ActionMeta } from 'react-select';

export interface UserFilters {
    roles?: SelectValueLabel[];
}

type Props = {
    filters: UserFilters;
    onChange: (filters: UserFilters) => void;
    onFilter: (filters: UserFilters) => void;
}

const UsersFiltersScreen = ({ filters, onChange, onFilter }: Props) => {
    const { t } = useTranslation();

    const form = useForm<UserFilters>({ shouldUnregister: false, shouldFocusError: true, defaultValues: filters });

    const [rolesOptions, setRoles] = useState<SelectValueLabel[]>([]);

    const getData = async () => {
        const options = await RolesService.getAllWithoutSystem();
        const roles = options.map(r => ({ value: r.id, label: (r.readOnly === true || r.system === true) ? t(('common.roles.' + r.name) as any) : r.name }));
        setRoles(roles);
    }

    useEffect(() => {
        getData();
    }, []);

    const clearFilters = () => {
        form.reset();
        form.setValue('roles', []);
        onInputChange();
        onSubmit(form.getValues());
    }

    const onSubmit = (f: UserFilters) => {
        onFilter(f);
    }

    const onInputChange = () => {
        onChange(form.getValues());
    }

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className={styles.formContent}>
                <div>
                    <FormItem>
                        <Row>
                            <Col xs={12}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('roles.title')}</Label>
                                    {rolesOptions.length > 0 && <Controller
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    options={rolesOptions}
                                                    isClearable
                                                    ignoreMenuPortalTarget
                                                    isMulti
                                                    placeholder={t('roles.title')}
                                                    isDisabled={false}
                                                    onChange={(newValue: unknown, _: ActionMeta<unknown>) => {
                                                        field.onChange((newValue as any)?.map((x: SelectValueLabel) => ({ value: x.value })));
                                                    }}
                                                    value={field.value ? rolesOptions.filter(x => field?.value?.find((y: SelectValueLabel) => y.value === x.value)) : []}
                                                    filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                                />
                                            );
                                        }}
                                        control={form.control}
                                        name="roles"
                                        defaultValue={form.getValues('roles')} />}
                                </FormItem>
                            </Col>
                        </Row>
                    </FormItem>
                </div>
                <div className={styles.buttonsFooter}>
                    <FormItem>
                        <Button
                            text={t('common.remove')}
                            size={'normal'}
                            preset={'secondary'}
                            onClick={clearFilters}
                            type='reset' />
                        <Button
                            type='submit'
                            text={t('common.apply')}
                            size={'normal'}
                        />
                    </FormItem>
                </div>
            </div>
        </form>
    );
}

export default memo(UsersFiltersScreen);