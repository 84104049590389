import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { DuesUserDto, LatestPartnerNumbersDto, UserDto } from 'api/users/models/UserDto';
import Loading from 'common/services/Loading';
import UsersService from 'api/users/UsersService';
import Logger from 'common/services/Logger';
import Button from 'common/components/button/Button';
import InputError from 'common/components/inputError/InputError';
import Label from 'common/components/label/Label';
import FormItem from 'common/components/formItem/FormItem';
import { useFieldArray, useForm } from 'react-hook-form';
import { DATEPICKER_FORMAT_DEFAULT, DATE_FORMAT_DEFAUT, DEFAULT_EMAIL_RULES_WITH_REQUIRED, DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE, removeAccents } from 'Config';
import GreyArrow from 'assets/svg/Arrow-grey.svg';
import React, { useEffect, useState } from 'react';
import RolesService from 'api/roles/RolesService';
import CheckBoxController from 'common/components/checkBox/CheckBoxController';
import { RoleDto } from 'api/roles/models/RoleDto';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { CheckAvailabilityDto } from 'api/common/models/CheckAvailabilityDto';
import styles from './UsersDetails.module.scss';
import { Col, Row } from 'react-flexbox-grid';
import CustomFile from 'common/models/CustomFile';
import MediaSelector from 'common/components/mediaSelector/MediaSelector';
import { Reducers } from 'store/types';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import { updateRefreshToken } from 'store/authentication/action';
import { useHistory, useParams } from 'react-router-dom';
import Input from 'common/components/input/Input';
import AssociationsService from 'api/associations/AssociationsService';
import SelectController from 'common/components/select/SelectController';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { FaCalendarAlt, FaTrash } from 'react-icons/fa';
import DateTimePickerController from 'common/components/dateTimePicker/DateTimePickerController';
import InputGroup from 'common/components/inputGroup/InputGroup';
import moment from 'moment';
import { AssociationsUserDto } from 'api/users/models/AssociationsUserDto';

type Props = {
};

const UsersDetails: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { id, type } = useParams<{ id: string, type: string }>();
    const isDetails = type === 'details';
    const [user, setUser] = useState<UserDto>();
    const [latestNumbers, setLatestNumbers] = useState<LatestPartnerNumbersDto>();
    const form = useForm<UserDto>({ shouldUnregister: false, defaultValues: { ...user } });
    const { register, handleSubmit, setValue, formState: { errors }, watch, reset, control } = form;
    const [allRoles, setAllRoles] = useState<RoleDto[]>([]);
    const [allAssociations, setAllAssociations] = useState<SelectValueLabel[]>([]);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<UserDto | null>(null);
    const [mediaFile, setMediaFile] = useState<CustomFile | null>(null);
    const [removeMedia, setRemoveMedia] = useState(false);
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasUsersWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_USERS_WRITE']);
    const { fields: associationsUser, append: appendAssociationsUser, remove: removeAssociationsUser } = useFieldArray({
        control,
        name: 'associationsUsers',
        keyName: 'formId'
    });
    const { fields: duesUser, append: appendDuesUser, remove: removeDuesUser } = useFieldArray({
        control,
        name: 'duesUser',
        keyName: 'formId'
    });



    const dispatch = useDispatch();
    const history = useHistory();

    const goToList = () => {
        history.push(`/security/users`);
    };

    const onCancel = () => {
        if (type == 'details') {
            history.push('/security/users');
        } else if (!id) {
            history.goBack();
        } else {
            getUser();
            history.push(`/security/users/details/${id}`);
        }
    };

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await UsersService.remove(itemToRemove);
            onCancelRemove();
            goToList();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete user`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    const getUser = async () => {
        Loading.show();

        try {
            const rolesList = await RolesService.getAll();

            const options = await AssociationsService.getAll();
            const associationsList = options.map(item => ({ value: item.id, label: item.name }));
            setAllAssociations(associationsList);


            let u: UserDto = { id: '', realName: '', email: '', stam: '', stamFop: '', numSocio1: '', numSocio2: '', numSocio3: '', phoneNumber: '', roles: [], temporaryRoles: [], associationsUsers: [], toSendNotification: false, numBiCc: '', numTaxPayer: '', birthDate: null, address: '', zipCode: '', city: '', country: '' };



            if (!!id) {
                u = await UsersService.getById(id);
                if (u.associationsUsers == null) {
                    u.associationsUsers = [];
                }
            }
            setLatestNumbers(await UsersService.getLatestPartnerNumbers());


            setAllRoles([...rolesList]);


            u.temporaryRoles = rolesList.map(role => {
                const __role = { ...role };
                __role.checked = (u.roles?.includes(__role.name) ?? false);
                return __role;
            });

            u.temporaryRoles.forEach(role => {
                if (role.readOnly || role.system) {
                    role.name = t(('common.roles.' + role.name) as any);
                }
            });


            for (const item of u.associationsUsers || []) {
                if (item.associationId != null && item.associatesTypeId != undefined) {
                    const _associatesTypes = await AssociationsService.getAllAssociatesTypeByAssociationId(item.associationId);
                    const associatesTypesList = _associatesTypes.map(c => ({ value: c.id ? c.id : '', label: c.name }));

                    item.allAssociateTypes = associatesTypesList;
                }
            }

            setUser(u);
            reset(u);

            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't get catalog users types`,
                error
            );
            Loading.hide();
        }
    }

    useEffect(() => {
        getUser();
    }, [type, isDetails]);

    const showRemoveItemDialog = async (item: UserDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const getAllAssociatesTypeByAssociationId = async (selectedId: string | null, rowIndex: number) => {
        try {
            if (selectedId) {
                Loading.show();
                const _associatesTypes = await AssociationsService.getAllAssociatesTypeByAssociationId(selectedId);
                const associatesTypesList = _associatesTypes.map(item => ({ value: item.id ? item.id : '', label: item.name }));

                form.setValue(`associationsUsers.${rowIndex}.allAssociateTypes`, associatesTypesList);
            } else {
                form.setValue(`associationsUsers.${rowIndex}.allAssociateTypes`, []);
            }

        } catch (error) {

            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.ASSOCIATIONDUES, `Couldn't get associates type`, error);
        } finally {
            Loading.hide();
        }
    };


    const onSubmit = async (formData: UserDto) => {
        try {

            if (!formData.temporaryRoles || formData.temporaryRoles?.find(e => e.checked === true) == null) {
                addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                return;
            }

            const emailData: CheckAvailabilityDto = { table: 'asp_net_users', column: 'normalized_email', value: formData.email.toLocaleUpperCase(), companyId: true, itemEdit: user?.id };
            const checkAvailabilityEmail = await UsersService.checkAvailability(emailData);
            if (!checkAvailabilityEmail) {
                addToast(t('common.messages.email_already_exists'), { appearance: 'warning' });
                return;
            }

            Loading.show();
            if (user) {
                user.realName = formData.realName;
                user.email = formData.email;
                user.phoneNumber = formData.phoneNumber;
                user.stam = formData.stam;
                user.stamFop = formData.stamFop;
                user.numSocio1 = formData.numSocio1;
                user.numSocio2 = formData.numSocio2;
                user.numSocio3 = formData.numSocio3;
                user.roles = [];
                user.removeMedia = removeMedia;
                user.associationsUsers = formData.associationsUsers;
                user.toSendNotification = formData.toSendNotification;
                user.numBiCc = formData.numBiCc;
                user.numTaxPayer = formData.numTaxPayer;
                user.birthDate = formData.birthDate;
                user.address = formData.address;
                user.zipCode = formData.zipCode;
                user.city = formData.city;
                user.country = formData.country;

                if (formData.temporaryRoles) {
                    formData.temporaryRoles.forEach((r, i) => {
                        if (r.checked) {
                            user.roles?.push(allRoles[i].name);
                        }
                    });
                }
            }


            if (user && user.id != '') {
                await UsersService.update(user, mediaFile);
            } else if (user) {
                await UsersService.create(user, mediaFile);
            }

            if (user?.id === loggedUser?.id) {
                dispatch(updateRefreshToken());
            }

            Loading.hide();
            addToast(t('common.messages.record_save_success'), {
                appearance: 'success',
            });
            onCancel();
        } catch (error) {
            addToast(t('common.messages.record_save_error'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't create or update user`,
                error
            );
            Loading.hide();
        }
    };

    const onAddAvatar = (files: CustomFile[]) => {
        if (files.length > 0) {
            setMediaFile(files[0]);
        }
    }

    const onRemoveAvatar = () => {
        setRemoveMedia(true);
        if (user) {
            setUser({
                ...user,
                media: null,
            });
        }
    }


    const addItemAssociatesType = () => {
        const associate: AssociationsUserDto = {
            id: null
            , associationId: null
            , allAssociateTypes: []
            , associatesTypeId: null
        };
        appendAssociationsUser(associate);
    }
    const removeItemAssociatesType = (rowIndex: any) => {
        if (user) {
            removeAssociationsUser(rowIndex);
        }
    }

    const addItemDues = () => {
        const associate: DuesUserDto = {
            id: null
        };
        appendDuesUser(associate);
    }
    const removeItemDues = (rowIndex: any) => {
        if (user) {
            removeDuesUser(rowIndex);
        }
    }


    const onError = async () => {
        addToast(t('common.errors.required_fields_empty'), { appearance: 'warning' });
    }

    return (
        <div>
            <div className={styles.pageNameContainer}>
                <div className={styles.pageNameContent} onClick={() => goToList()} >
                    <img className={styles.pageNameContentImage} src={GreyArrow} />
                    <span className={styles.pageNameContentText}>
                        {t('users.title')}
                    </span>
                </div>
            </div>

            <form className={styles.form} onSubmit={handleSubmit(onSubmit, onError)}>
                <Row>
                    {(type != 'details' || (type == 'details' && user?.media)) && <Col xs={12} >
                        <div className={styles.avatarChooser}>
                            <Label className={styles.label}>
                                {t('users.photo' as any)}
                            </Label>
                            <MediaSelector
                                isDetails={type == 'details'}
                                isMulti={false}
                                onAddMedias={onAddAvatar}
                                onRemoveMedia={onRemoveAvatar}
                                initialMedias={user?.media ? [user?.media] : []}
                            />
                        </div>
                    </Col>}

                    <Col xs={12}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.name' as any)}*
                            </Label>
                            <Input
                                placeholder={t('users.name')}
                                defaultValue={user?.realName}
                                {...register('realName', { ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                disabled={type == 'details'}
                            />
                            <InputError error={errors.realName} />
                        </FormItem>
                    </Col>

                    <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.email' as any)}*
                            </Label>
                            <Input
                                placeholder={t('users.email')}
                                defaultValue={user?.email}
                                {...register('email', { ...DEFAULT_EMAIL_RULES_WITH_REQUIRED })}
                                disabled={type == 'details'}
                            />
                            <InputError error={errors.email} />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.phoneNumber' as any)}
                            </Label>
                            <Input
                                placeholder={t('users.phoneNumber')}
                                defaultValue={user?.phoneNumber}
                                {...register('phoneNumber')}
                                disabled={type == 'details'}
                            />
                            <InputError error={errors.phoneNumber} />
                        </FormItem>
                    </Col>

                    <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>{t('users.birthDate')}</Label>
                            <DateTimePickerController
                                form={form}
                                placeholderText={t('users.birthDate')}
                                customInput={<InputGroup icon={<FaCalendarAlt />} />}
                                name={'birthDate'}
                                autoComplete='off'
                                disabled={type == 'details'} />

                            <InputError error={errors?.birthDate} />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.numBiCc' as any)}
                            </Label>
                            <Input
                                placeholder={t('users.numBiCc')}
                                defaultValue={user?.numBiCc}
                                {...register('numBiCc')}
                                disabled={type == 'details'}
                            />
                            <InputError error={errors.numBiCc} />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.numTaxPayer' as any)}
                            </Label>
                            <Input
                                placeholder={t('users.numTaxPayer')}
                                defaultValue={user?.numTaxPayer}
                                {...register('numTaxPayer')}
                                disabled={type == 'details'}
                            />
                            <InputError error={errors.numTaxPayer} />
                        </FormItem>
                    </Col>
                    <Col xs={12}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.address' as any)}
                            </Label>
                            <Input
                                placeholder={t('users.address')}
                                defaultValue={user?.address}
                                {...register('address')}
                                disabled={type == 'details'}
                            />
                            <InputError error={errors.address} />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.zipCode' as any)}
                            </Label>
                            <Input
                                placeholder={t('users.zipCode')}
                                defaultValue={user?.zipCode}
                                {...register('zipCode')}
                                disabled={type == 'details'}
                            />
                            <InputError error={errors.zipCode} />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.city' as any)}
                            </Label>
                            <Input
                                placeholder={t('users.city')}
                                defaultValue={user?.city}
                                {...register('city')}
                                disabled={type == 'details'}
                            />
                            <InputError error={errors.city} />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.country' as any)}
                            </Label>
                            <Input
                                placeholder={t('users.country')}
                                defaultValue={user?.country}
                                {...register('country')}
                                disabled={type == 'details'}
                            />
                            <InputError error={errors.country} />
                        </FormItem>
                    </Col>


                    <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.stam' as any)}
                            </Label>
                            <Input
                                placeholder={t('users.stam')}
                                defaultValue={user?.stam}
                                {...register('stam')}
                                disabled={type == 'details'}
                            />
                            <InputError error={errors.stam} />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.stamFop' as any)}
                            </Label>
                            <Input
                                placeholder={t('users.stamFop')}
                                defaultValue={user?.stamFop}
                                {...register('stamFop')}
                                disabled={type == 'details'}
                            />
                            <InputError error={errors.stamFop} />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.numSocio1' as any)}
                            </Label>

                            <Input
                                placeholder={`${type == 'details' ? t('users.numSocio1') : t('users.numberSuggestion') + latestNumbers?.numSocio1}`}
                                defaultValue={user?.numSocio1}
                                {...register('numSocio1')}
                                disabled={type == 'details'}
                            />
                            <InputError error={errors.numSocio1} />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.numSocio2' as any)}
                            </Label>
                            <Input
                                placeholder={`${type == 'details' ? t('users.numSocio2') : t('users.numberSuggestion') + latestNumbers?.numSocio2}`}
                                defaultValue={user?.numSocio2}
                                {...register('numSocio2')}
                                disabled={type == 'details'}
                            />
                            <InputError error={errors.numSocio2} />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.numSocio3' as any)}
                            </Label>
                            <Input
                                placeholder={t('users.numSocio3')}
                                defaultValue={user?.numSocio3}
                                {...register('numSocio3')}
                                disabled={type == 'details'}
                            />
                            <InputError error={errors.numSocio3} />
                        </FormItem>
                    </Col>

                </Row>

                {/* SO ESCOLHE NO NOVO USER SE QUER NOTIFICAR POR EMAIL DA CRIACAO DA CONTA */}
                {!id && <Row>
                    <Col xs={12} md={6}>
                        <CheckBoxController key='toSendNotification' form={form as any} {...register('toSendNotification')} label={t('users.toSendNotification')} disabled={type == 'details'} />
                    </Col>
                </Row>}

                <Row>
                    <Col xs={12} md={6}>
                        <FormItem>
                            <h3>
                                {t('roles.title')}*
                            </h3>
                            <div className={styles.roles}>
                                {user?.temporaryRoles.map((role, i) => {
                                    return (<CheckBoxController key={i} form={form as any} name={`temporaryRoles[${i}].checked`} label={role.name} disabled={type == 'details'} />);
                                })}
                            </div>
                            <InputError error={form.formState.isSubmitted && watch('temporaryRoles').filter(x => x.checked).length === 0 ? { type: 'dateRange', message: t('common.errors.error_no_role_selected') } : undefined} />
                        </FormItem>
                    </Col>

                </Row>
                <Row>
                    <h3>{t('associations.associates_types')}  </h3>
                    {!isDetails && <ScreenHeaderButton icon={AddIcon} onClick={() => addItemAssociatesType()} />}
                </Row>
                {associationsUser.map((row, rowIndex: number) => (
                    <Row className={styles.associationType} key={row.formId}>
                        <Col className={styles.textRight} lg={12}>
                            {!isDetails && <FaTrash size={16} onClick={() => removeItemAssociatesType(rowIndex)} />}
                        </Col>
                        <Col xs={12} md={3} key={`col-association-${rowIndex}`}>
                            <FormItem>
                                <Label className={styles.label}>{t('associations.title')} </Label>
                                <SelectController
                                    form={form as any}
                                    name={`associationsUsers.${rowIndex}.associationId`}
                                    options={allAssociations}
                                    placeholder={t('associations.title')}
                                    isDisabled={isDetails}
                                    rules={{ required: false }}
                                    isClearable={true}
                                    filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                    onChangeSelect={(data: SelectValueLabel) => {
                                        setValue(`associationsUsers.${rowIndex}.associationId`, data ? data.value : null);

                                        // //sempre que altera a associacao limpa a drop do tipo de socio
                                        setValue(`associationsUsers.${rowIndex}.associatesTypeId`, null);

                                        // faz get dos tipos de socios da associacao selecionada
                                        getAllAssociatesTypeByAssociationId(data ? data.value : null, rowIndex);
                                    }}
                                />
                                {/* <InputError error={errors.associationId} /> */}
                            </FormItem>
                        </Col>
                        <Col xs={12} md={3} key={`col-associate_type-${rowIndex}`}>
                            <FormItem>
                                <Label className={styles.label}>{t('association_dues.details.associate_type')} </Label>

                                <SelectController
                                    form={form as any}
                                    name={`associationsUsers.${rowIndex}.associatesTypeId`}
                                    options={watch(`associationsUsers.${rowIndex}.allAssociateTypes`, [])}
                                    placeholder={t('association_dues.details.associate_type')}
                                    isDisabled={isDetails}
                                    rules={{ required: false }}
                                    isClearable={true}
                                    filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                    onChangeSelect={(data: SelectValueLabel) => {
                                        setValue(`associationsUsers.${rowIndex}.associatesTypeId`, data ? data.value : null);
                                    }}
                                />
                                {/* <InputError error={errors.associatesTypeId} /> */}
                            </FormItem>
                        </Col>
                        <Col xs={12} md={3}>
                            <FormItem>
                                <Label className={styles.label}>{t('users.start_date')} </Label>
                                <DateTimePickerController
                                    form={form}
                                    placeholderText={t('association_dues.details.start_date')}
                                    customInput={<InputGroup icon={<FaCalendarAlt />} />}
                                    name={`associationsUsers.${rowIndex}.startDate`}
                                    autoComplete='off'
                                    disabled={isDetails} />

                                {/* <InputError error={errors?.startDate} /> */}
                            </FormItem>
                        </Col>
                        <Col xs={12} md={3}>
                            <FormItem>
                                <Label className={styles.label}>{t('users.end_date')} </Label>
                                <DateTimePickerController
                                    form={form}
                                    placeholderText={t('association_dues.details.end_date')}
                                    customInput={<InputGroup icon={<FaCalendarAlt />} />}
                                    name={`associationsUsers.${rowIndex}.endDate`}
                                    autoComplete='off'
                                    disabled={isDetails} />

                                {/* <InputError error={errors?.endDate} /> */}
                            </FormItem>
                        </Col>

                    </Row>
                ))}


                {/* QUOTAS /> */}
                <Row>
                    <h3>{t('associations.dues')}  </h3>
                    {!isDetails && <ScreenHeaderButton icon={AddIcon} onClick={() => addItemDues()} />}
                </Row>
                {duesUser.map((row, rowIndex: number) => (
                    <Row className={styles.associationType} key={row.formId}>
                        <Col className={styles.textRight} lg={12}>
                            {!isDetails && <FaTrash size={16} onClick={() => removeItemDues(rowIndex)} />}
                        </Col>
                        <Col xs={12} md={3} key={`col-dues-user-${rowIndex}`}>
                            <FormItem>
                                <Label className={styles.label}>{t('associations.dues_type')} </Label>

                            </FormItem>
                        </Col>


                    </Row>
                ))}


                <div className={styles.buttonContainer}>
                    <Button preset={'secondary'}
                        type='button'
                        text={t('common.cancel')}
                        onClick={() => onCancel()} />
                    {type == 'details' && hasUsersWritePolicy &&
                        <Button
                            type="button"
                            text={t('common.remove')}
                            preset={'danger'}
                            onClick={() => showRemoveItemDialog({ id: user?.id } as UserDto)} />
                    }
                    {type == 'details' && hasUsersWritePolicy &&
                        <Button
                            type='button'
                            text={t('common.edit')}
                            onClick={() => history.push(`/security/users/edit/${id}`)} />
                    }
                    {type != 'details' && hasUsersWritePolicy &&
                        <Button
                            type='submit'
                            text={t('common.save')} />
                    }
                </div>
            </form>
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_record_with_ident', { name: itemToRemove?.realName ?? '' })}></QuestionYesNo>
        </div>
    );
};

export default UsersDetails;
