import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import * as qs from 'qs';
import { LOGGER_LOG_TYPE, DEFAULT_LANGUAGE, API_BASE_URL, STORAGE } from '../../Config';
import { logout } from '../../store/authentication/action';
import store from '../../store/store';
import Logger from './Logger';
import Storage from './Storage';
import CustomFile from '../models/CustomFile';
import { UserToken } from 'api/account/models/UserToken';

const axiosConfig: AxiosRequestConfig = {
    responseType: 'json',
    // withCredentials: true,
};

const http = axios.create(axiosConfig);

class Http {
    private language: string = DEFAULT_LANGUAGE;

    constructor() {
        this.initInterceptors();
    }

    private initInterceptors() {
        // Request
        http.interceptors.request.use(async (config: AxiosRequestConfig) => {
            config.baseURL = API_BASE_URL;
            config.headers = await this.getHeaders();
            return config;
        }, (error: AxiosError) => error);

        // Response
        http.interceptors.response.use(async (response: AxiosResponse): Promise<any> => {
            return response.data;
        }, async (error: AxiosError) => {
            if (error.response && error.response.status === 401) {
                store.dispatch(logout() as any);
            }

            return Promise.reject(error);
        });
    }

    public setLanguage(language: string) {        
        if (language) {
            this.language = language;
        }
    }

    public async post<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
        return http.post<any, T>(url, data, config).catch((error: any) => this.onError(error, url));
    }

    public async postWithFile<T>(url: string, data: any, files: CustomFile[], config?: AxiosRequestConfig): Promise<T> {
        const formData = new FormData();

        for (const file of files) {
            formData.append(`${file.containerName ? file.containerName : 'files[]'}`, file);
        }

        formData.append('model', JSON.stringify(data));

        return http.post<any, T>(url, formData, {
            ...(config || {}),
            headers: {
                'content-type': 'multipart/form-data',
            },
        }).catch((error: any) => this.onError(error, url));
    }

    public async put<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
        return http.put<any, T>(url, data, config).catch((error: any) => this.onError(error, url));
    }

    public async putWithFile<T>(url: string, data: any, files: CustomFile[], config?: AxiosRequestConfig): Promise<T> {
        const formData = new FormData();

        for (const file of files) {
            formData.append(`${file.containerName ? file.containerName : 'files[]'}`, file);
        }
        
        formData.append('model', JSON.stringify(data));

        return http.put<any, T>(url, formData, {
            ...(config || {}),
            headers: {
                'content-type': 'multipart/form-data',
            },
        }).catch((error: any) => this.onError(error, url));
    }

    public async get<T>(url: string, params: any = null): Promise<T> {
        if (params) {
            url = `${url}?${qs.stringify(params)}`;
        }
        return http.get<any, T>(url).catch((error: any) => this.onError(error, url));
    }

    private getHeaders = async () => {
        const token: UserToken = Storage.getObject(STORAGE.AUTH_USER_TOKEN);        
        return {
            'Authorization': token ? `${token.tokenType} ${token.token}` : null,
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'X-App-Lang': this.language,
        } as AxiosRequestHeaders;
    };

    private onError(error: any, url: string) {
        Logger.error(LOGGER_LOG_TYPE.REQUEST, error.message, { url, error }, false);
        return Promise.reject(error);
    }
}

export default new Http();
