import { useTranslation } from 'react-i18next';
import styles from './ProjectCard.module.scss';
import { ProjectDto, ServerProjectListDto } from 'api/projects/models/ProjectDto';
import { Condition } from 'api/projects/models/Condition';
import WarningIcon from 'assets/svg/warning.svg';
import DangerIcon from 'assets/svg/danger.svg';
import ReactTooltip from 'react-tooltip';
import DateFormat from 'common/components/dateFormat/dateFormat';

type Props = {
    project: ProjectDto;
};

const ProjectCard: React.FC<Props> = ({ project }: Props) => {
    const { t } = useTranslation();

    const renderConditions = (): JSX.Element => {
        return <>
            {project.condition === Condition.OK && <>
                <a data-tip data-for={`condition-${project.id}`}><div className={styles.conditionOk} /></a>
            </>}
            {project.condition === Condition.WARNING && <>
                <a data-tip data-for={`condition-${project.id}`}>
                    <div className={styles.conditionImageContainer}>
                        <img src={WarningIcon} alt="" className={styles.conditionImage} />
                    </div>
                </a>
            </>}
            {project.condition === Condition.DANGER && <>
                <a data-tip data-for={`condition-${project.id}`}>
                    <div className={styles.conditionImageContainer}>
                        <img src={DangerIcon} alt="" className={styles.conditionImage} />
                    </div>
                </a>
            </>}
            <ReactTooltip id={`condition-${project.id}`}>
                <span>{t(`projects.list.condition.${project.condition}` as any)}</span>
            </ReactTooltip>
        </>
    }

    return (
        <>
            <div className={styles.titleContainer}>
                <div className={styles.title}>{project.name}</div>
            </div>
            <div className={styles.requirementContainer}>
                <div>
                    {t('projects.list.condition.title')}:
                </div>
                <div className={styles.conditions}>
                    {renderConditions()}
                </div>
            </div>
            <div>
                <div className={styles.serverListContainer}>
                    <div className={styles.serverListTitle}>{t('projects.list.serverList')}:</div>
                    <div className={styles.serverCell}>
                        {(project.serverProject ?? []).map((x: ServerProjectListDto, i: number) =>
                            <span key={`index-${i}`} className={styles.serverRepetitionContainer}>
                                {x.serverName}
                                {!!x.repetition && x.repetition > 1 && <div className={styles.serverRepetition}>
                                    {x.repetition}
                                </div>}
                            </span>)}
                    </div>
                </div>
            </div>
            <div className={styles.fill} />
            <div className={styles.dateContainer}>
                <span>{t('projects.list.createdDate')}</span>
                <DateFormat className={styles.date} value={project.createdDate ?? ''} />
            </div>
            {project.hasAlerts && <div className={styles.alertContainer} >
                <a data-tip data-for={`alerts-${project.id}`}>
                    <span className={styles.floatingBadge}>
                        {(project.alertCount ?? 0) > 99 ? <small>99+</small> : project.alertCount}
                    </span>
                </a>
            </div>}
            <ReactTooltip id={`alerts-${project.id}`}>
                <span>{t('projects.list.tooltips.alerts')}</span>
            </ReactTooltip>
        </>
    );
};

export default ProjectCard;
