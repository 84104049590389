export interface RuleDto {    
    id: string;    
    name: string;
    expression: string;
    concatType?: RuleConcatType;
}

export enum RuleConcatType {
    OR = 'OR',
    AND = 'AND',
}
