import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

type Props = {
} & NumberFormatProps;

const MoneyFormat: React.FC<Props> = ({ value, suffix, ...props }: Props) => {

    return (
        <NumberFormat
            value={value}
            displayType={props.displayType || 'text'}
            thousandSeparator={' '}
            decimalSeparator={','}
            decimalScale={2}
            fixedDecimalScale={false}
            suffix={' ' + (suffix ?? '€')}
            {...props}
        />
    );
}

export default MoneyFormat;
