import { useTranslation } from 'react-i18next';
import styles from './UserCard.module.scss';
import { UserDto, UserRoleDto } from 'api/users/models/UserDto';
import noImage from 'assets/svg/desktop_default_photo.svg';

type Props = {
    user: UserDto;
};

const UserCard: React.FC<Props> = ({ user }: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <div className={styles.logoContainer}>
                <div className={styles.logoContent}>
                    {!!user.mediaUrl && <img className={styles.logo} src={user.mediaUrl} />}
                    {!user.mediaUrl && <img className={styles.logo} src={noImage} />}
                </div>
            </div>
            <div className={styles.titleContainer}>
                <div className={styles.title}>{user.realName}</div>
            </div>
            <div className={styles.roleContainer}>
                {(user.rolesCardInfo ?? []).length > 0 && <>                    
                    <div className={styles.role}>
                        {(user.rolesCardInfo ?? []).map((x: UserRoleDto, index: number) => <span key={index}>{x.system ? t(('common.roles.' + x.name) as any) : x.name}</span>)}
                        {!!user.numSocio1 && <span>{t('users.numSocio1' as any)}: {user.numSocio1}</span>}
                        {!!user.numSocio2 && <span>{t('users.numSocio2' as any)}: {user.numSocio2}</span>}
                    </div>
                </>}
                {(user.rolesCardInfo ?? []).length == 0 && <span>{t('users.list.no_associated_roles')}</span>}
            </div>
        </>
    );
};

export default UserCard;
