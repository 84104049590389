import React from 'react';
import { useTranslation } from 'react-i18next';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';

const ForbiddenScreen: React.FC = () => {

    const { t } = useTranslation();

    return (
        <ScreenTitle title={t('common.errors.page_forbidden_title')}>
            <h1>403 - {t('common.errors.page_forbidden')}</h1>
        </ScreenTitle>
    );
}

export default ForbiddenScreen;
