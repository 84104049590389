 import Http from '../../common/services/Http';
 import { Paged } from '../common/types/Page';
 import { AssociationDuesSearchCriteria } from './models/AssociationDuesSearchCriteria';
 import { AssociationDuesDto } from './models/AssociationDuesDto';
 
class AssociationDuesService {

   
    public getList(criteria: AssociationDuesSearchCriteria) {
        return Http.get<Paged<AssociationDuesDto>>('associationDues', criteria);
    }

    public getById(id: string) {
        return Http.get<AssociationDuesDto>('associationDues/' + id);
    }

    public getByAssociationId(id: string) {
        return Http.get<AssociationDuesDto[]>('associationDues/all-associationdues-by-associationid/' + id);
    }

    public create(model: AssociationDuesDto) {
        return Http.post('associationDues', model);
    }

    public update(model: AssociationDuesDto) {
        return Http.put('associationDues', model);
    }

    public remove(model: AssociationDuesDto) {
        return Http.put('associationDues/deactivate', model);
    }
    
    public getAll() {
        return Http.get<AssociationDuesDto[]>('associationDues/all');
    }
        

}

export default new AssociationDuesService();