import Http from '../../common/services/Http';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { Paged } from 'api/common/types/Page';
import { ServerDto } from './models/ServerDto';
import { ServerSearchCriteria } from './models/ServerSearchCriteria';

class ServersService {

    public getList(criteria: ServerSearchCriteria) {
        return Http.get<Paged<ServerDto>>('servers', criteria);
    }

    public get(id: string) {
        return Http.get<ServerDto>(`servers/${id}`);
    }

    public catalog() {
        return Http.get<SelectValueLabel[]>('servers/catalog');
    }

    public create(model: ServerDto) {
        return Http.post<string>('servers', model);
    }

    public update(model: ServerDto) {
        return Http.put('servers', model);
    }

    public remove(id: string) {
        return Http.put('servers/deactivate', { id: id });
    }
}

export default new ServersService();