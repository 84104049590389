import BigNumber from 'bignumber.js';

export { }; // this file needs to be a module

Number.prototype.plus = function (this: number, b: number): number {
    return new BigNumber(this).plus(b).toNumber();
};

Number.prototype.minus = function (this: number, b: number): number {
    return new BigNumber(this).minus(b).toNumber();
};

Number.prototype.multiply = function (this: number, b: number): number {
    return new BigNumber(this).multipliedBy(b).toNumber();
};

Number.prototype.divide = function (this: number, b: number): number {
    return new BigNumber(this).dividedBy(b).toNumber();
};

Number.prototype.toBig = function (this: number): BigNumber {
    return new BigNumber(this);
};

Array.prototype.sum = function (this: number[]): number {
    return this.map(x => new BigNumber(x)).reduce((a, b) => a.plus(b), new BigNumber(0)).toNumber();
};
