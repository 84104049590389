import Loading from 'common/services/Loading';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { useEffect, useState } from 'react';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import { Col, Row } from 'react-flexbox-grid';
import FormItem from 'common/components/formItem/FormItem';
import { Label } from 'recharts';
import styles from './DuesScreen.module.scss';
import Input from 'common/components/input/Input';
import { useHistory, useParams } from 'react-router-dom';
import { UserDto } from 'api/users/models/UserDto';
import { useForm } from 'react-hook-form';
import Box from 'common/components/box/Box';
import UsersService from 'api/users/UsersService';
import Button from 'common/components/button/Button';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { DuesListItemDto } from 'api/dues/models/DuesListDto';
import DuesService from 'api/dues/DuesService';
import CheckBox from 'common/components/checkBox/CheckBox';

export type DuesRouteParams = {
    type: 'details' | 'edit' | 'new';
    userId: string;
}

type Props = {
};


const DuesScreen: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { userId, type } = useParams<DuesRouteParams>();
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const isDetails = type === 'details';
    const isNew = type === 'new';
    const history = useHistory();
    const form = useForm<UserDto>();
    const { register, handleSubmit, formState: { errors }, reset } = form;
    const [user, setUser] = useState<UserDto | null>(null);
    const [duesList, setDuesList] = useState<DuesListItemDto[]>([]);

    const getData = async () => {
        try {
            Loading.show();

            console.log('styles', styles);
            if (!!userId) {
                const u = await UsersService.getById(userId);                
                setUser(u);

            }

            
            const listDues = await DuesService.getByIdUser(userId);
            console.log('listDues',listDues);
            setDuesList(listDues);


            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });
            Loading.hide();
        }
    }

    const onSubmit = async (formData: UserDto) => {
        try {
            // Loading.show();

            // formData.categorieUsers = usersList.flatMap(item => item.checked ? [item.id] : []);

            // if (isNew) {
            //     const id = await CategoriesService.create(formData);
            //     history.push(`/categories/details/${id}`);
            // } else {
            //     await CategoriesService.update(formData);
            //     history.push(`/categories/details/${categorieId}`);
            // }

            Loading.hide();
        } catch (error) {
            // addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            // Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't create or update categories`, error);
            // Loading.hide();
        }
    };

    const onCancel = () => {
        history.push(isDetails || isNew ? '/dues' : `/dues/details/${userId}`);
    }

    const navigateToEdit = () => {
        history.push(`/dues/edit/${userId}`)
    }

    const onCancelRemove = () => {
        setShowRemoveModal(false);
    }
    const onRemove = async () => {
        try {
            //TODO
            // Loading.show();
            // setShowRemoveModal(false);
            // await CategoriesService.remove(categorieId);
            // history.push('/categories');
            // Loading.hide();
        } catch (error) {
            // addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            // Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't remove the categorie`, error);
            // Loading.hide();
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <ScreenTitle title={t('dues.list.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('dues.list.title')} />
                <Box>
                    <form onSubmit={handleSubmit(onSubmit)} >
                        <div className={styles.container}>

                            <Row className={styles.rowSpace}>
                                <Col className={styles.colSpace} xs={12} md={6}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('dues.details.member_name')} *</Label>
                                        <Input
                                            placeholder={t('dues.details.member_name')}
                                            defaultValue={user?.realName}
                                            {...register('realName', { required: true })}
                                            disabled={isDetails}
                                        />
                                        {/* <InputError error={errors?.name} /> */}
                                    </FormItem>
                                </Col>
                            </Row>

                            <Row>
                                <h3>{t('associations.dues')}  </h3>
                            </Row>
                            <div className={styles.container}>
                                <div className={styles.configContainer}>
                                    <div className={styles.usersContainer}>
                                        <div className={styles.searchContainer}>
                                            {/* <InputSearch inputContainerClassName={styles.search} onChangeValue={debouncedUser} placeholder={t('common.search')}></InputSearch> */}
                                        </div>
                                        <table className={styles.table}>
                                            <thead>
                                                <tr>
                                                    <td className={styles.tableHeader}>
                                                        <span className={styles.tableHeaderTitle}>{t('users.title')}</span>
                                                        {/* <CheckBox checked={isAllUsersChecked(filterUser != '')} onChange={e => { onAllUsersChecked(e.target.checked) }} /> */}
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(duesList).map((row, rowIndex) => (
                                                    <tr key={`row-u-${rowIndex}`} className={styles.rowHover}>
                                                        <td className={styles.bodyColumn}>
                                                            <div className={styles.userContainer}>
                                                                <div className={styles.userInfo}>
                                                                    <span className={styles.name}>{row.name}</span>
                                                                    <span className={styles.email}>({row.name})</span>
                                                                </div>
                                                                <div className={styles.checkbox}>
                                                                    {/* <CheckBox
                                                                        onChange={e => {
                                                                            setUsersList([
                                                                                ...usersList.map(r => {
                                                                                    if (r.id === row.id) {
                                                                                        r.checked = e.target.checked;
                                                                                    }
                                                                                    return r;
                                                                                }),
                                                                            ]);
                                                                        }}
                                                                        checked={row.checked || false}
                                                                        key={row.id}
                                                                    /> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <QuestionYesNo
                            onNo={onCancelRemove}
                            onYes={onRemove}
                            isVisible={showRemoveModal}
                            message={t('common.messages.remove_record_with_ident', { name: user?.realName ?? '' })} />
                        <div className={styles.buttonContainer}>
                            {isDetails &&
                                <>
                                    <Button
                                        preset={'secondary'}
                                        type='button'
                                        text={t('common.cancel')}
                                        onClick={() => onCancel()}
                                    />
                                    <Button
                                        preset={'danger'}
                                        type='button'
                                        text={t('common.remove')}
                                        onClick={() => setShowRemoveModal(true)}
                                    />
                                    <Button
                                        type='button'
                                        text={t('common.edit')}
                                        onClick={() => navigateToEdit()}
                                    />
                                </>
                            }
                            {!isDetails &&
                                <>
                                    <Button preset={'secondary'}
                                        type='button'
                                        text={t('common.cancel')}
                                        onClick={() => onCancel()}
                                    />
                                    <Button
                                        type='submit'
                                        text={t('common.save')}
                                    />
                                </>
                            }
                        </div>

                    </form>
                </Box>

            </ScreenContainer>
        </ScreenTitle>
    )
};

export default DuesScreen;
