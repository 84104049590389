import { UserProfile } from 'api/account/models/UserProfile';
import Box from 'common/components/box/Box';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Reducers } from 'store/types';
import { LOGGER_LOG_TYPE } from 'Config';
import { Col, Row } from 'react-flexbox-grid';
import Label from 'common/components/label/Label';
import styles from './MagazinesScreen.module.scss';
import { Controller, useForm } from 'react-hook-form';
import InputError from 'common/components/inputError/InputError';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import Input from 'common/components/input/Input';
import Button from 'common/components/button/Button';
import MagazinesService from 'api/magazines/MagazinesService';
import CategorieService from 'api/categories/CategoriesService';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import UsersService from 'api/users/UsersService';
import { MagazineDto } from 'api/magazines/models/MagazineDto';
import DateTimePickerController from 'common/components/dateTimePicker/DateTimePickerController';
import { FaCalendarAlt } from 'react-icons/fa';
import InputGroup from 'common/components/inputGroup/InputGroup';
import moment from 'moment';
import MediaSelector from 'common/components/mediaSelector/MediaSelector';
import CustomFile from 'common/models/CustomFile';
import Canvas from 'common/components/canvas/Canvas';
import FormItem from 'common/components/formItem/FormItem';
import Select from 'common/components/select/Select';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { removeAccents } from 'Config';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { EventGA, PageView } from 'common/services/Analitics';


export type MagazineRouteParams = {
    type: 'details' | 'edit' | 'new';
    magazineId: string;
    itemType: 'list' | 'details' | 'edit' | 'new';
    itemId: string;
}

type Props = {
};

const MagazinesScreen: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { magazineId, type } = useParams<MagazineRouteParams>();
    const isDetails = type === 'details';
    const isNew = type === 'new';
    const history = useHistory();
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_MAGAZINES_WRITE']);
    const hasReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_MAGAZINES_READ']);
    const hasReadCategoriesPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_CATEGORIES_READ']);
    const form = useForm<MagazineDto>();
    const { setValue, register, handleSubmit, formState: { errors }, reset } = form;
    const [magazine, setMagazine] = useState<MagazineDto | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [mediaFile, setMediaFile] = useState<CustomFile[] | null>([]);
    const [allCategories, setAllCategories] = useState<SelectValueLabel[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);

    const getData = async () => {
        let categories = null;

        if (hasReadCategoriesPolicy) {//se pode ver as categorias
            const options = await CategorieService.getAll();
            categories = options.map(item => ({ value: item.id, label: item.name }));
            setAllCategories(categories);
        }else if(hasWritePolicy){ //nao pode ver as categorias e tem politica para editar a resvista, está alguma coisa mal nas politicas
            addToast(t('magazines.messages.error_read_categorie_policy'), { appearance: 'warning' });
            return;
        }

        if (magazineId) {
            try {
                Loading.show();
                const result = await MagazinesService.get(magazineId);
                if (categories) {
                    result.selectedCategorie = result.categorieId ? categories.find(x => x.value === result.categorieId) : undefined;
                }
                setMagazine(result);
                setCurrentPage(1);
                setTotalPages((result.attatchments?.length) || 0);
                reset(result);

                PageView(t('magazines.title'));
                EventGA('REVISTAS', window.location.pathname, result.name);
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.error_load_info'), { appearance: 'error' });
                Loading.hide();
                onCancel();                
            }
        }
    }

    const onSubmit = async (formData: MagazineDto) => {
        try {
            Loading.show();
            formData.attachmentsIdToRemove = magazine?.attachmentsIdToRemove;
            formData.categorieId = formData?.selectedCategorie?.value;

            if (isNew) {
                const id = await MagazinesService.create(formData, mediaFile);
                history.push(`/magazines/details/${id}`);
            } else {
                await MagazinesService.update(formData, mediaFile);
                history.push(`/magazines/details/${magazineId}`);
            }

            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't create or update magazines`, error);
            Loading.hide();
        }
    };

    useEffect(() => {
        getData();
    }, [type]);

    const onCancel = () => {
        history.push(isDetails || isNew ? '/magazines' : `/magazines/details/${magazineId}`);
    }

    const navigateToEdit = () => {
        history.push(`/magazines/edit/${magazineId}`)
    }

    const onCancelRemove = () => {
        setShowRemoveModal(false);
    }

    const onRemove = async () => {
        try {
            Loading.show();
            setShowRemoveModal(false);
            await MagazinesService.remove(magazineId);
            history.push('/magazines');
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't remove the magazine`, error);
            Loading.hide();
        }
    }


    const onAddAttatchment = (files: CustomFile[]) => {
        if (files.length > 0) {
            setMediaFile(files);
        }
    }

    const onRemoveAttatchment = (mediaId: string) => {

        if (magazine) {
            magazine.attachmentsIdToRemove = magazine?.attachmentsIdToRemove ? magazine?.attachmentsIdToRemove : [];
            magazine.attachmentsIdToRemove.push(mediaId);
        }
    }

    const canCreate = (): JSX.Element => {
        return <Box>
            <form onSubmit={handleSubmit(onSubmit)} >
                <div className={styles.container}>
                    <Row className={styles.rowSpace}>
                        <Col className={styles.colSpace} xs={12} md={6}>
                            <Label className={styles.label}>{t('magazines.details.name')} *</Label>
                            <Input
                                placeholder={t('magazines.details.name')}
                                defaultValue={magazine?.name}
                                {...register('name', { required: true })}
                                disabled={isDetails}
                            />
                            <InputError error={errors?.name} />
                        </Col>
                        <Col className={styles.colSpace} xs={12} md={6}>
                            <div >
                                <Label className={styles.label}>{t('magazines.details.releaseDate')}</Label>
                                <DateTimePickerController
                                    form={form}
                                    placeholderText={t('magazines.details.releaseDate')}
                                    onChange={(newDate: Date) => {
                                        setValue('releaseDate', newDate);
                                    }}
                                    selected={magazine != undefined && magazine.releaseDate ? moment(magazine.releaseDate).toDate() : null}
                                    customInput={<InputGroup icon={<FaCalendarAlt />} />}
                                    name={'magazine.releaseDate'}
                                    autoComplete='off'
                                    disabled={false} />

                                <InputError error={errors?.releaseDate} />
                            </div>
                        </Col>
                        <Col className={styles.colSpace} xs={12} md={6}>
                            <FormItem>
                                <Label className={styles.bold}>{t('categories.title')}</Label>
                                {allCategories.length > 0 && <Controller
                                    render={({ field }) => {
                                        return (
                                            <Select
                                                options={allCategories}
                                                isClearable
                                                ignoreMenuPortalTarget
                                                placeholder={t('roles.title')}
                                                isDisabled={false}
                                                onChange={(newValue: unknown) => {
                                                    field.onChange(newValue);
                                                }}
                                                value={field.value}
                                                filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                            />
                                        );
                                    }}
                                    control={form.control}
                                    name="selectedCategorie"
                                    defaultValue={form.getValues('selectedCategorie')} />}
                            </FormItem>

                            {/* <FormItem>
                                <Label className={styles.label}>
                                    {t('categories.title')}*
                                </Label>
                                <div className={styles.roles}>
                                    {allCategories.map((role, i) => {
                                        return (<CheckBoxController key={i} form={form as any} name={`allCategories[${i}].checked`} label={role.name} disabled={type == 'details'} />);
                                    })}
                                </div>
                            </FormItem> */}
                        </Col>

                        <Col className={styles.colSpace} xs={12} md={6}>
                            <MediaSelector
                                isDetails={type == 'details'}
                                isMulti={true}
                                onAddMedias={onAddAttatchment}
                                onRemoveMedia={onRemoveAttatchment}
                                initialMedias={magazine?.attatchments ? magazine?.attatchments : []}
                            />

                        </Col>
                    </Row>




                </div>
                <QuestionYesNo
                    onNo={onCancelRemove}
                    onYes={onRemove}
                    isVisible={showRemoveModal}
                    message={t('common.messages.remove_record_with_ident', { name: magazine?.name ?? '' })} />
                <div className={styles.buttonContainer}>
                    {isDetails &&
                        <>
                            <Button
                                preset={'secondary'}
                                type='button'
                                text={t('common.cancel')}
                                onClick={() => onCancel()}
                            />
                            <Button
                                preset={'danger'}
                                type='button'
                                text={t('common.remove')}
                                onClick={() => setShowRemoveModal(true)}
                            />
                            <Button
                                type='button'
                                text={t('common.edit')}
                                onClick={() => navigateToEdit()}
                            />
                        </>
                    }
                    {!isDetails &&
                        <>
                            <Button preset={'secondary'}
                                type='button'
                                text={t('common.cancel')}
                                onClick={() => onCancel()}
                            />
                            <Button
                                type='submit'
                                text={t('common.save')}
                            />
                        </>
                    }
                </div>
            </form>
        </Box >;
    }

    const canRead = (): JSX.Element => {
        return <Box>
            <div className={styles.container}>
                <Row>
                    <Col className={styles.colSpace} xs={12} md={12}>
                        {(magazine?.attatchments ? magazine?.attatchments : []).filter((_, i) => i + 1 === currentPage).map(({ url }, index) => (
                            <Canvas
                                key={url}
                                imageToShow={url}
                                waterMark={userProfile?.realName + (userProfile?.stam ? ' - ' + userProfile?.stam : '')}
                            />
                        ))}
                        <PaginationWithInfo
                            itemName='Paginas'
                            currentPage={currentPage || 1}
                            pageItems={currentPage}
                            totalItems={totalPages}
                            itemsPerPage={1}
                            onChange={page => setCurrentPage(page)}
                        />
                    </Col>
                </Row>
            </div>
        </Box >;
    }

    const cannotPermission = (): JSX.Element => {
        return <Box>
            <div className={styles.permissionContainer}>
                <div>{t('common.no_permission')}</div>
                <Button
                    preset={'secondary'}
                    type='button'
                    text={t('common.cancel')}
                    onClick={() => onCancel()}
                />
            </div>
        </Box >;
    }

    return (
        <ScreenTitle title={t('magazines.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('magazines.title')} />
                {hasWritePolicy ? canCreate() : hasReadPolicy ? canRead() : cannotPermission()}
            </ScreenContainer>
        </ScreenTitle>
    );
};

export default MagazinesScreen;
