import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import Box from 'common/components/box/Box';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Reducers } from 'store/types';
import Loading from '../../common/services/Loading';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { CategorieSearchCriteria } from 'api/categories/models/CategoriesSearchCriteria';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from 'Config';
import { CategoriesDto } from 'api/categories/models/CategoriesDto';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { Paged } from 'api/common/types/Page';
import CategoriesService from 'api/categories/CategoriesService';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';

type Props = {
};

const CategoriesListScreen: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [criteria, setCriteria] = useState<CategorieSearchCriteria>({
        page: 1,
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        orderColumn: 'c.name',
        orderBy: 'desc'
    });

    const [categoriePage, setCategoriePage] = useState<Paged<CategoriesDto>>();

    const history = useHistory();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_MAGAZINES_WRITE']);

    const getData = async () => {
        try {
            Loading.show();
            const categories = await CategoriesService.getList(criteria);
            setCategoriePage(categories);
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });
            Loading.hide();
        }
    }
 
    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }    

    const tableColumns: ListingTableColumn<CategoriesDto>[] = [
        {
            field: 'name',
            name: t('categories.list.name'),
            onSearch: onTableFilter,
            searchField: 'c.name'
        }        
    ];
    

    const navigateToNew = () => {
        history.push('/categories/new');
    }

    const navigateToItem = (row: CategoriesDto) => {
        history.push(`/categories/details/${row.id}`);        
    }

    useEffect(() => {
        getData();
    }, [criteria]);


    return (
        <ScreenTitle title={t('categories.title')}>
            <ScreenContainer>
            <ScreenHeader title={t('categories.title')} >
                    {hasWritePolicy && <ScreenHeaderButton icon={AddIcon} onClick={() => navigateToNew()} />}
                </ScreenHeader>
                <Box>
                    <ListingTable
                        columns={tableColumns}
                        rows={categoriePage?.items || []}
                        onRowClick={row => navigateToItem(row)}
                        allowHover={true}
                        initialSearch={{ colField: 'c.name', isOrderAsc: true }}
                    />

                    <PaginationWithInfo
                        itemName={t('categories.list.title')}
                        currentPage={categoriePage?.currentPage || 1}
                        pageItems={categoriePage?.items.length || 0}
                        totalItems={categoriePage?.totalItems || 0}
                        onChange={page => setCriteria({ ...criteria, page })}
                    />
                </Box>
            </ScreenContainer>
        </ScreenTitle>
    );
};

export default CategoriesListScreen;
