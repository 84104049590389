import { useTranslation } from 'react-i18next';
import styles from './ProjectInfoCard.module.scss';
import { Condition } from 'api/projects/models/Condition';
import WarningIcon from 'assets/svg/warning.svg';
import DangerIcon from 'assets/svg/danger.svg';
import ReactTooltip from 'react-tooltip';
import DateFormat from 'common/components/dateFormat/dateFormat';
import { ProjectInfoDto } from 'api/servers/models/ServerDto';

type Props = {
    project: ProjectInfoDto;
};

const ProjectInfoCard: React.FC<Props> = ({ project }: Props) => {
    const { t } = useTranslation();

    const renderConditions = (): JSX.Element => {
        return <>
            {project.condition === Condition.OK && <>
                <a data-tip data-for={`condition-${project.id}`}><div className={styles.conditionOk} /></a>
            </>}
            {project.condition === Condition.WARNING && <>
                <a data-tip data-for={`condition-${project.id}`}>
                    <div className={styles.conditionImageContainer}>
                        <img src={WarningIcon} alt="" className={styles.conditionImage} />
                    </div>
                </a>
            </>}
            {project.condition === Condition.DANGER && <>
                <a data-tip data-for={`condition-${project.id}`}>
                    <div className={styles.conditionImageContainer}>
                        <img src={DangerIcon} alt="" className={styles.conditionImage} />
                    </div>
                </a>
            </>}
            <ReactTooltip id={`condition-${project.id}`}>
                <span>{t(`projects.list.condition.${project.condition}` as any)}</span>
            </ReactTooltip>
        </>
    }

    return (
        <>
            <div className={styles.titleContainer}>
                <div className={styles.title}>{project.name}</div>
            </div>
            <div className={styles.requirementContainer}>
                <div>
                    {t('projects.list.condition.title')}:
                </div>
                <div className={styles.conditions}>
                    {renderConditions()}
                </div>
            </div>
            <div className={styles.sizeContainer}>
                <span>{t('projects.details.size')}</span>
                <span className={styles.size}>{project.formattedSize}</span>
            </div>
            <div className={styles.fill} />
            <div className={styles.dateContainer}>
                <span>{t('projects.list.createdDate')}</span>
                <DateFormat className={styles.date} value={project.createdDate ?? ''} />
            </div>
        </>
    );
};

export default ProjectInfoCard;
