import { useWindowSize } from '@react-hook/window-size';
import { DiskSpaceInfoDto } from 'api/servers/models/ServerDto';
import { isTablet } from 'Config';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { PieChart, Pie, Cell } from 'recharts';
import styles from './ServerDiskChart.module.scss';

type TooltipContent = {
    name: string;
    size: string;
}

type Props = {
    diskSpace: DiskSpaceInfoDto[] | undefined
}

const ServerDiskChart: React.FC<Props> = ({ diskSpace }: Props) => {
    const { t } = useTranslation();
    const [windowWidth, windowHeight] = useWindowSize();
    const [containerWidth, setContainerWidth] = useState<number>(0);

    const setDimensions = () => {
        if (!ref.current) return;
        const width = isTablet(window.innerWidth) ? 200 : 300;
        setContainerWidth(width);
    };

    useEffect(() => {
        setTimeout(() => {
            setDimensions();
        }, 200);
    }, [windowWidth, windowHeight]);

    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [tooltipContent, setTooltipContent] = useState<TooltipContent | null>(null);

    const renderTooltip = (): JSX.Element => {
        if (!tooltipContent) return <></>;
        return <div>
            <div>
                <span>{tooltipContent.name}</span>
            </div>
            <div>
                <span>{t('servers.details.size', { size: tooltipContent.size })}</span>
            </div>
        </div>;
    }

    const chartData = useMemo(() => {
        const res = [];
        if (!diskSpace) return [];
        for (const disk of diskSpace) {
            res.push({ name: disk.name, value: disk.size, color: disk.color, formatted: disk.formattedSize });
        }

        return res;
    }, [diskSpace]);

    const ref = useRef<any>();

    return (
        <>
            <a data-tip data-for='condition' className={styles.containerTooltip}>
                <div className={styles.masterContainer}>
                    <div className={styles.container} ref={ref}>
                        <PieChart
                            width={containerWidth}
                            height={containerWidth}
                        >
                            <Pie
                                data={chartData}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                nameKey="name"
                                innerRadius={'75%'}
                                outerRadius={'90%'}
                                fill="#8884d8"
                                dataKey="value"
                                isAnimationActive={true}
                            >
                                {chartData.map((row, index) => (
                                    <Cell key={`cell-${index}`}
                                        fill={row.color}
                                        onMouseOver={(_: any) => {
                                            setTooltipContent({ name: row.name, size: row.formatted });
                                            setShowTooltip(true);
                                        }}
                                        onMouseOut={() => {
                                            setShowTooltip(false);
                                            setTooltipContent(null);
                                        }} />
                                ))}
                            </Pie>
                        </PieChart>
                    </div>
                    <div className={styles.legendContainer}>
                        {(diskSpace ?? []).map((x: DiskSpaceInfoDto, index: number) =>
                            <div
                                key={`legend-${index}`}
                                className={styles.legend}
                                onMouseOver={(_: any) => {
                                    setTooltipContent({ name: x.name, size: x.formattedSize });
                                    setShowTooltip(true);
                                }}
                                onMouseOut={() => {
                                    setShowTooltip(false);
                                    setTooltipContent(null);
                                }}>
                                <div className={styles.legendCircle}
                                    style={{ backgroundColor: x.color }}></div>
                                <span style={{ color: x.color }}>{x.name}</span>
                            </div>)}
                    </div>
                </div>
            </a>
            {showTooltip && <ReactTooltip id='condition'>
                {!!tooltipContent && renderTooltip()}
            </ReactTooltip>}
        </>
    );
}

export default memo(ServerDiskChart);