import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import Box from 'common/components/box/Box';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Reducers } from 'store/types';
import Loading from '../../common/services/Loading';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { ServerSearchCriteria } from 'api/servers/models/ServerSearchCriteria';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from 'Config';
import { ServerDto } from 'api/servers/models/ServerDto';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { Paged } from 'api/common/types/Page';
import ServersService from 'api/servers/ServersService';
import { Row } from 'react-flexbox-grid';
import Card from 'common/components/card/Card';
import { useDebouncedCallback } from 'use-debounce/lib';
import InputSearch from 'common/components/inputSearch/InputSearch';

type Props = {
};

const ServersListScreen: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [criteria, setCriteria] = useState<ServerSearchCriteria>({
        page: 1,
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        orderColumn: 'name',
        orderBy: 'asc'
    });

    const [serverPage, setServerPage] = useState<Paged<ServerDto>>();

    const history = useHistory();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_SERVERS_WRITE']);

    const getData = async () => {
        try {
            Loading.show();
            const servers = await ServersService.getList(criteria);
            setServerPage(servers);
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });
            Loading.hide();
        }
    }

    const navigateToNew = () => {
        history.push('/servers/new');
    }

    useEffect(() => {
        getData();
    }, [criteria]);

    const debounced = useDebouncedCallback((value: string) => {
        setCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);

    return (
        <ScreenTitle title={t('servers.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('servers.title')} >
                    <InputSearch onChangeValue={debounced} placeholder={t('servers.list.placeholders.search')} />
                    {hasWritePolicy && <ScreenHeaderButton icon={AddIcon} onClick={() => navigateToNew()} />}
                </ScreenHeader>
                <Box>
                    <Row>
                        {serverPage?.items.map((x: ServerDto, index: number) => <Card key={index} server={x} index={index} />)}
                    </Row>

                    <PaginationWithInfo
                        itemName={t('servers.list.title')}
                        currentPage={serverPage?.currentPage || 1}
                        pageItems={serverPage?.items.length || 0}
                        totalItems={serverPage?.totalItems || 0}
                        onChange={page => setCriteria({ ...criteria, page })}
                    />
                </Box>
            </ScreenContainer>
        </ScreenTitle>
    );
};

export default ServersListScreen;
