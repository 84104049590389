import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import Box from 'common/components/box/Box';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Reducers } from 'store/types';
import { LOGGER_LOG_TYPE, Types } from 'Config';
import { Col, Row } from 'react-flexbox-grid';
import Label from 'common/components/label/Label';
import styles from './ProjectDetailsScreen.module.scss';
import Loading from 'common/services/Loading';
import Input from 'common/components/input/Input';
import Button from 'common/components/button/Button';
import { ProjectDto } from 'api/projects/models/ProjectDto';
import ProjectsService from 'api/projects/ProjectsService';
import Tabs, { TabItem } from 'common/components/tabs/Tabs';
import ServerProjectTab from './tabs/serverProjectTab/ServerProjectTab';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import Logger from 'common/services/Logger';
import AlertTab from 'screens/servers/tabs/alertTab/AlertTab';

type Props = {
};

const ProjectDetailsScreen: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const { projectId, type } = useParams<{ projectId: string, type: Types }>();
    const history = useHistory();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVerificationsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_PROJECTS_WRITE']);

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

    const [project, setProject] = useState<ProjectDto | null>(null);

    const getData = async () => {
        try {
            if (!projectId) return;
            Loading.show();

            const result = await ProjectsService.get(projectId);
            setProject(result);

            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const onCancelRemove = () => {
        setShowRemoveModal(false);
    }

    const onRemove = async () => {
        try {
            Loading.show();
            setShowRemoveModal(false);
            await ProjectsService.remove(projectId);
            history.push('/projects');
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't remove the project`, error);
            Loading.hide();
        }
    }

    useEffect(() => {
        getData();
    }, [type, projectId]);

    const onCancel = () => {
        history.push('/projects');
    }

    const navigateToEdit = () => {
        history.push(`/projects/edit/${projectId}`)
    }

    const tabs: TabItem[] = useMemo(() => {
        const items: TabItem[] = [{
            id: 'alerts',
            title: t('alerts.title'),
            badge: (project?.alertList ?? []).filter(x => x.isRead === false).length,
            content: <AlertTab alerts={project?.alertList ?? []} reload={getData} />
        }];

        if (!project || !project.serverProject?.length) return items;

        for (let i = 0; i < project.serverProject.length; i++) {
            const sp = project.serverProject[i];
            items.push({
                id: `serverProject-${i}`,
                error: !sp.conditionsMet,
                title: `${sp.serverName}`,
                content: <ServerProjectTab serverProject={sp} files={(project.files ?? []).filter(x => x.serverProjectId === sp.id).sort(function (a, b) {
                    return new Date(b.fileDate).getTime() - new Date(a.fileDate).getTime();
                })} />
            })
        }

        return items;
    }, [project?.serverProject, project?.alertList]);

    const [tabId, setTabId] = useState<string>('serverProject-0');

    return (
        <ScreenTitle title={t('projects.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('projects.title')} />
                <Box>
                    <div className={styles.container}>
                        <Row className={styles.rowSpace}>
                            <Col xs={12} className={styles.rowSpace}>
                                <Label className={styles.label}>{t('servers.details.name')} *</Label>
                                <Input
                                    placeholder={t('servers.details.name')}
                                    defaultValue={project?.name}
                                    disabled={true}
                                />
                            </Col>
                            <Col xs={6} className={styles.rowSpace}>
                                <Label className={styles.label}>{t('projects.details.color')} *</Label>
                                {project?.color ? <Input
                                    disabled={true}
                                    style={{ backgroundColor: project?.color, color: project?.color, cursor: 'pointer' }}
                                /> : <div>{t('projects.details.no_color')}</div>}
                                
                            </Col>
                        </Row>
                        <div className={styles.tabContainer}>
                            <Tabs
                                items={tabs}
                                activeTabId={tabId}
                                onChange={setTabId}
                            />
                        </div>
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button
                            preset={'secondary'}
                            type='button'
                            text={t('common.cancel')}
                            onClick={() => onCancel()}
                        />
                        <Button
                            preset={'danger'}
                            type='button'
                            text={t('common.remove')}
                            onClick={() => setShowRemoveModal(true)}
                        />
                        <Button
                            type='button'
                            text={t('common.edit')}
                            onClick={() => navigateToEdit()}
                        />
                    </div>
                    <QuestionYesNo
                        onNo={onCancelRemove}
                        onYes={onRemove}
                        isVisible={showRemoveModal}
                        message={t('common.messages.remove_record_with_ident', { name: project?.name ?? '' })} />
                </Box>
            </ScreenContainer>
        </ScreenTitle>
    );
};

export default ProjectDetailsScreen;
