import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import Box from 'common/components/box/Box';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Reducers } from 'store/types';
import Loading from '../../common/services/Loading';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { MagazineSearchCriteria } from 'api/magazines/models/MagazineSearchCriteria';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from 'Config';
import { MagazineDto } from 'api/magazines/models/MagazineDto';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { Paged } from 'api/common/types/Page';
import MagazinesService from 'api/magazines/MagazinesService';
import { Row } from 'react-flexbox-grid';
import Card from 'common/components/card/Card';
import { useDebouncedCallback } from 'use-debounce/lib';
import InputSearch from 'common/components/inputSearch/InputSearch';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import DateFormat from 'common/components/dateFormat/dateFormat';

type Props = {
};

const MagazinesListScreen: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [criteria, setCriteria] = useState<MagazineSearchCriteria>({
        page: 1,
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        orderColumn: 'm.name',
        orderBy: 'desc'
    });

    const [magazinePage, setMagazinePage] = useState<Paged<MagazineDto>>();

    const history = useHistory();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_MAGAZINES_WRITE']);

    const getData = async () => {
        try {
            Loading.show();
            const magazines = await MagazinesService.getList(criteria);
            setMagazinePage(magazines);
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });
            Loading.hide();
        }
    }
 
    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const renderDateCell = (magazine: MagazineDto) => <DateFormat value={magazine.releaseDate ?? ''} />; 

    const tableColumns: ListingTableColumn<MagazineDto>[] = [
        {
            field: 'name',
            name: t('magazines.list.name'),
            onSearch: onTableFilter,
            searchField: 'm.name'
        }        
    ];
    if(hasWritePolicy){
        tableColumns.push({
            field: 'releaseDate',
            name: t('magazines.list.releaseDate'),
            onSearch: onTableFilter,
            searchField: 'm.releaseDate',
            renderCell: renderDateCell
        });
    }
    

    const navigateToNew = () => {
        history.push('/magazines/new');
    }

    const navigateToItem = (row: MagazineDto) => {
        history.push(`/magazines/details/${row.id}`);        
    }

    useEffect(() => {
        getData();
    }, [criteria]);


    return (
        <ScreenTitle title={t('magazines.title')}>
            <ScreenContainer>
            <ScreenHeader title={t('magazines.title')} >
                    {hasWritePolicy && <ScreenHeaderButton icon={AddIcon} onClick={() => navigateToNew()} />}
                </ScreenHeader>
                <Box>
                    <ListingTable
                        columns={tableColumns}
                        rows={magazinePage?.items || []}
                        onRowClick={row => navigateToItem(row)}
                        allowHover={true}
                        initialSearch={{ colField: 'm.name', isOrderAsc: true }}
                    />

                    <PaginationWithInfo
                        itemName={t('magazines.list.title')}
                        currentPage={magazinePage?.currentPage || 1}
                        pageItems={magazinePage?.items.length || 0}
                        totalItems={magazinePage?.totalItems || 0}
                        onChange={page => setCriteria({ ...criteria, page })}
                    />
                </Box>
            </ScreenContainer>
        </ScreenTitle>
    );
};

export default MagazinesListScreen;
