/* eslint-disable unused-imports/no-unused-vars */
import Box from 'common/components/box/Box';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import Loading from '../../common/services/Loading';
import AlertsService from 'api/alerts/AlertsService';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { AlertSearchCriteria } from 'api/alerts/models/AlertSearchCriteria';
import DateFormat from 'common/components/dateFormat/dateFormat';
import { AlertDto } from 'api/alerts/models/AlertDto';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import { Paged } from 'api/common/types/Page';
import Logger from 'common/services/Logger';
import { useDispatch, useSelector } from 'react-redux';
import { updateTotals } from 'store/alerts/action';
import { UserProfile } from 'api/account/models/UserProfile';
import { Reducers } from 'store/types';
import UsersService from 'api/users/UsersService';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';


type Props = {
};

const AlertsListScreen: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const { addToast } = useToasts();    
    const [alertPage, setAlertPage] = useState<Paged<AlertDto>>();
    const [criteria, setCriteria] = useState<AlertSearchCriteria>({
        page: 1,
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        orderColumn: 'date',
        orderBy: 'asc'
    });
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasAlertsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ALERTS_WRITE']);

    const getData = async () => {
        try {
            Loading.show();
            const alerts = await AlertsService.getList(criteria);
            setAlertPage(alerts);
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }
 
    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }


    const getRowCellStyle = (row: AlertDto): CSSProperties => {
        return row.readDate ?  { fontWeight : 'normal' } : { fontWeight: 'bold' };
    }

    const tableColumns: ListingTableColumn<AlertDto>[] = [
        {
            field: 'date',
            name: t('alerts.list.date'),
            onSearch: onTableFilter,
            searchField: 'a.date',
            renderCell: row => (<span style={getRowCellStyle(row)}><DateFormat value={row.date ?? ''} /></span>),
        },
        {
            field: 'content',
            name: t('alerts.list.content'),            
            searchField: 'a.content',
            renderCell: row => (<span style={getRowCellStyle(row)}>{row.content}</span>),
        },
        {
            field: 'origin',
            name: t('alerts.list.origin.title'),
            onSearch: onTableFilter,
            searchField: 'a.origin',
            renderCell: row => (<span style={getRowCellStyle(row)}>{t(`alerts.list.origin.${row.origin}`)}</span>)            
        },     
        {
            field: 'eventType',
            name: t('alerts.list.event_type.title'),
            onSearch: onTableFilter,
            searchField: 'a.event_type',
            renderCell: row => (<span style={getRowCellStyle(row)}>{t(`alerts.list.event_type.${row.eventType}`)}</span>)            
        },     
        {
            field: 'serverName',
            name: t('alerts.list.server_name'),
            onSearch: onTableFilter,
            searchField: 's.name',
            renderCell: row => (<span style={getRowCellStyle(row)}>{row.serverName}</span>),
        },        
        {
            field: 'projectName',
            name: t('alerts.list.project_name'),
            onSearch: onTableFilter,
            searchField: 'p.name',
            renderCell: row => (<span style={getRowCellStyle(row)}>{row.projectName}</span>),
        }       
    ];


    useEffect(() => {
        getData();
    }, [criteria]);

    
    const readAlert = async (row: AlertDto, event: any) => {
        if (row.readDate) {      
            return;
        }

        try {
            Loading.show();

            if (hasAlertsWritePolicy) {
                await AlertsService.read(row.id);
                dispatch(updateTotals());
                getData();
            }
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.ALERTS, `Couldn't get alerts list`, error);
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    }

    return (
        <ScreenTitle title={t('alerts.title')}>
        <ScreenContainer>
            <ScreenHeader title={t('alerts.title')} ></ScreenHeader>
            <Box>
            <ListingTable
                        columns={tableColumns}
                        rows={alertPage?.items || []}                      
                        onRowClick={(row, _, event) => readAlert(row, event)}
                        allowHover={true}
                        initialSearch={{ colField: 's.date', isOrderAsc: true }}
                    />
                <PaginationWithInfo
                        itemName={t('alerts.title')}
                        currentPage={alertPage?.currentPage || 1}
                        pageItems={alertPage?.items.length || 0}
                        totalItems={alertPage?.totalItems || 0}
                        onChange={page => setCriteria({ ...criteria, page })}
                    />
            </Box>
            </ScreenContainer>
        </ScreenTitle>
    );
};

export default AlertsListScreen;
