import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { initAuthentication } from './store/authentication/action';
import { GoogleAnaliticsConfig } from 'Config';
import { initGA } from 'common/services/Analitics';


type Props = {
    children: React.ReactElement;
}

const Setup: React.FC<Props> = ({ children }: Props) => {

    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        dispatch(initAuthentication(() => setLoaded(true)));
        initGA(GoogleAnaliticsConfig || '');
    });

    return loaded ? children : null;
}

export default Setup;
