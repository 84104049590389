import ReactGA from 'react-ga4';


export const initGA = (trackingID: string) => {           
    ReactGA.initialize(trackingID); 
}

export const PageView = (title? : string) => {      
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: title });
}

export const EventGA = (category: string, action: string, label: string) => {
  
  if (label === undefined) {
    return;
  } 
  ReactGA.event({
      category: category,
      action: action,
      label: label
    });
};


export type Category = 'REVISTAS';
//export type Category = 'LIST_CANDIDATES' | 'EMPLOYEE_OFFER' | 'COMPANIES' | 'OFFER' | 'CV'  | 'EDUCATIONAL_ESTABLISHMENT' | 'COMPANY_SEARCH';
