import { useTranslation } from 'react-i18next';
import styles from './AlertCard.module.scss';
import DateFormat from 'common/components/dateFormat/dateFormat';
import { AlertInfoDto } from 'api/servers/models/ServerDto';
import { FaRegEnvelopeOpen, FaRegEnvelope } from 'react-icons/fa'
import { DATE_TIME_FORMAT_DEFAUT } from 'Config';

type Props = {
    alert: AlertInfoDto;
};

const AlertCard: React.FC<Props> = ({ alert }: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <div className={styles.titleContainer}>
                <div className={styles.title} style={ !alert.isRead ? { fontWeight: 'bold' } : undefined }>{alert.content}</div>
            </div>
            <div className={styles.originContainer}>
                <div>
                    {t('alerts.list.origin.title')}:
                </div>
                <div className={styles.origin}>{t(`alerts.list.origin.${alert.origin}` as any)}</div>
            </div>
            <div className={styles.eventContainer}>
                <div>
                    {t('alerts.list.event_type.title')}:
                </div>
                <div className={styles.event}>{t(`alerts.list.event_type.${alert.eventType}` as any)}</div>
            </div>
            {alert.origin === 'SERVER_PROJECT' && <div className={styles.projectContainer}>
                <div>
                    {t('alerts.list.project_name')}:
                </div>
                <div className={styles.project}>
                    {alert.projectName}
                </div>
            </div>}
            {alert.origin === 'SERVER_FOLDER' && <div className={styles.projectContainer}>
                <div>
                    {t('alerts.list.folder_name')}:
                </div>
                <div className={styles.project}>
                    {alert.folderName}
                </div>
            </div>}
            {alert.origin === 'SERVER' && <div className={styles.projectContainer}>
                <div>
                    {t('alerts.list.server_name')}:
                </div>
                <div className={styles.project}>
                    {alert.serverName}
                </div>
            </div>}
            
            <div className={styles.fill} />
            <div className={styles.dateContainer}>
                <span>{t('alerts.list.date')}</span>
                <DateFormat className={styles.date} value={alert.date ?? ''} format={DATE_TIME_FORMAT_DEFAUT} />
            </div>
            <div className={styles.messageContainer}>
                {alert.isRead ? <FaRegEnvelopeOpen size={20} /> : <FaRegEnvelope size={20} />}
            </div>
        </>
    );
};

export default AlertCard;
