import { useHistory } from 'react-router-dom';
import { Col } from 'react-flexbox-grid';
import styles from './Card.module.scss';
import { ProjectDto, ServerProjectFilesDto } from 'api/projects/models/ProjectDto';
import { FolderDto } from 'api/folders/models/FolderDto';
import { AlertInfoDto, ProjectInfoDto, ServerDto } from 'api/servers/models/ServerDto';
import ProjectCard from './components/project/ProjectCard';
import ServerCard from './components/server/ServerCard';
import ProjectInfoCard from './components/projectInfo/ProjectInfoCard';
import AlertCard from './components/alert/AlertCard';
import FolderCard from './components/folder/FolderCard';
import Loading from 'common/services/Loading';
import UsersService from 'api/users/UsersService';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import AlertsService from 'api/alerts/AlertsService';
import { updateTotals } from 'store/alerts/action';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import FileCard from './components/file/FileCard';
import { UserDto } from 'api/users/models/UserDto';
import UserCard from './components/user/UserCard';

type Props = {
    project?: ProjectDto;
    projectInfo?: ProjectInfoDto;
    server?: ServerDto;
    alert?: AlertInfoDto;
    file?: ServerProjectFilesDto;
    folder?: FolderDto;
    user?: UserDto;
    index: number;
    large?: boolean;
    action?: () => void;
    noAction?: boolean;
};

const Card: React.FC<Props> = ({ project, server, projectInfo, alert, file, folder, user, index, large, noAction, action }: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasAlertsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ALERTS_WRITE']);

    const onClick = () => {
        if(noAction) return;
        
        if (!!alert) {
            readAlert();
        } else {
            navigateToItem();
        }
    }

    const readAlert = async () => {
        if (!alert) return;

        if (alert.isRead) {
            return;
        }

        try {
            Loading.show();

            if (hasAlertsWritePolicy) {
                await AlertsService.read(alert.id);
                dispatch(updateTotals());
            }

            alert = { ...alert, isRead: true };
            !!action && action();
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.ALERTS, `Couldn't get alerts list`, error);
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const navigateToItem = () => {
        if (!!server) {
            history.push(`/servers/details/${server.id}`);
        }
        if (!!folder) {
            history.push(`/folders/details/${folder.id}`);
        }
        if (!!project) {
            history.push(`/projects/details/${project.id}`);
        }
        if (!!projectInfo) {
            history.push(`/projects/details/${projectInfo.id}`);
        }
        if (!!user) {
            history.push(`/security/users/details/${user.id}`);
        }
    }

    return (
        <Col xs={12} md={6} lg={large ? 4 : 3} xl={large ? 3 : 2} key={`card-${index}`} onClick={onClick} style={{ cursor: !noAction ? 'pointer' : 'default', margin: '0.5rem 0' }}>
            <div className={styles.cardContainer}>
                {!!project && <ProjectCard project={project} />}
                {!!server && <ServerCard server={server} />}
                {!!projectInfo && <ProjectInfoCard project={projectInfo} />}
                {!!alert && <AlertCard alert={alert} />}
                {!!file && <FileCard file={file} />}
                {!!folder && <FolderCard folder={folder} />}
                {!!user && <UserCard user={user} />}
            </div>
        </Col>
    );
};

export default Card;
